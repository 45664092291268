import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { InstanceStatusModel, FailedAttempt } from '../models';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import { InstanceStatusInfoModalComponent } from '../instance-status-info-modal/instance-status-info-modal.component';
import { DatePipe } from '@angular/common';
import { BehaviourService } from '../behaviour.service';
import { InstanceStatusErrorModalComponent } from '../instance-status-error-modal/instance-status-error-modal.component';

@Component({
    selector: 'app-instance-status',
    templateUrl: './instance-status.component.html',
    styleUrls: ['./instance-status.component.scss'],
})
export class InstanceStatusComponent implements OnInit, OnChanges {
    @Input() status: InstanceStatusModel = null;
    @Input() statusToCompare: InstanceStatusModel = null;
    @Input() showDetails: boolean = null;

    failedStatusResponses: Array<InstanceStatusModel> = new Array<InstanceStatusModel>();
    private modalRef: MatDialogRef<any, any>;
    private readonly maxFailedResponses: number = 3;
    public showErrorDetails: boolean;

    constructor(private modalService: MatDialog, private datePipe: DatePipe, private behaviourService: BehaviourService) {}

    ngOnInit() {
        this.behaviourService.errorDetails.subscribe(showErrorDetails => {
            this.showErrorDetails = showErrorDetails;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.status) {
            this.storeStatus(changes.status.currentValue as InstanceStatusModel);
            this.updateLastTime();
        }
    }

    open() {
        this.modalRef = this.modalService.open(InstanceStatusInfoModalComponent);
        this.modalRef.afterClosed().subscribe(result => {
            this.modalRef = null;
        });

        this.updateModalInfo();
    }

    openErrorDetailsHistory(status: InstanceStatusModel) {
        this.modalRef = this.modalService.open(InstanceStatusErrorModalComponent);
        this.modalRef.afterClosed().subscribe(result => {
            this.modalRef = null;
        });
        this.updateModalInfo();
    }

    public getBuildInfo(status) {
        if (status) {
            if (status.version && status.suffix) {
                return `${status.version} - ${status.suffix}`;
            }
            if (status.version && !status.suffix) {
                return this.status.version;
            }
            if (!status.version && status.suffix) {
                return status.suffix;
            }
        }
    }

    getFormattedDate(date: Date): string {
        return date ? this.datePipe.transform(date, 'mediumTime') : 'N/A';
    }

    private storeStatus(status: InstanceStatusModel) {
        if (status && !status.healthy) {
            this.failedStatusResponses.push(status);
            if (this.failedStatusResponses.length > this.maxFailedResponses) {
                this.failedStatusResponses.shift();
            }
        }
    }

    private updateLastTime() {
        if (this.status) {
            this.status.lastTimeUp = this.status.healthy ? this.status.requestDate : this.status.lastTimeUp;
            this.status.lastTimeFailed = this.failedStatusResponses.length > 0 ? this.failedStatusResponses[this.failedStatusResponses.length - 1].requestDate : null;
            this.updateModalInfo();
        }
    }

    private updateModalInfo() {
        if (this.modalRef) {
            this.modalRef.componentInstance.currentStatus = this.status;
            this.modalRef.componentInstance.failedStatusResponses = this.failedStatusResponses;
        }
    }

    public checkVersion(status: InstanceStatusModel): boolean {
        return this.statusToCompare &&
        (status.environment !== this.statusToCompare.environment &&
        this.getFullVersion(status) !== this.getFullVersion(this.statusToCompare));
    }

    public getFullVersion(status: InstanceStatusModel) {
        return status.version + '-' + status.suffix;
    }

    public checkEnv(status: InstanceStatusModel): boolean {
        return this.statusToCompare && status.environment === this.statusToCompare.environment;
    }

    public getLastFailed(status: InstanceStatusModel): string {
        const lastFailedAttempt  = this.getLastFailedAttempt(status);
        if (lastFailedAttempt) {
            return this.getFormattedDate(lastFailedAttempt.failedDt);
        }
        return 'N/A';
     }

     public getLastRecovered(status: InstanceStatusModel): string {
        const lastFailedAttempt  = this.getLastFailedAttempt(status);
        if (lastFailedAttempt) {
            return this.getFormattedDate(lastFailedAttempt.healtyDt);
        }
        return 'N/A';
     }

     public getLastAttempts(status: InstanceStatusModel): number {
        const lastFailedAttempt  = this.getLastFailedAttempt(status);
        if (lastFailedAttempt) {
            return lastFailedAttempt.attempts;
        }
        return 0;
     }

     private getLastFailedAttempt(status: InstanceStatusModel): FailedAttempt {
        if (status.properties.failedAttempts) {
            const fails = status.properties.failedAttempts.length;
            if (fails > 0) {
                return status.properties.failedAttempts[fails - 1];
            }
        }
        return null;
     }
}
