import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { StatusComponent } from './status/status.component';
import { SummaryComponent } from './summary/summary.component';
import { HttpClientModule } from '@angular/common/http';
import { InstanceStatusComponent } from './instance-status/instance-status.component';
import { DashboardService } from './dashboard.service';
import { InstanceStatusInfoModalComponent } from './instance-status-info-modal/instance-status-info-modal.component';
import { InstanceStatusErrorModalComponent } from './instance-status-error-modal/instance-status-error-modal.component';
import { BehaviourService } from './behaviour.service';
import { ControlsComponent } from './controls/controls.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [DashboardComponent, StatusComponent, SummaryComponent, InstanceStatusComponent, InstanceStatusInfoModalComponent, InstanceStatusErrorModalComponent, ControlsComponent,],
    imports: [CommonModule, DashboardRoutingModule, HttpClientModule, MatIconModule,
         MatButtonModule,
          MatDialogModule, MatTooltipModule],
    providers: [DashboardService, BehaviourService, DatePipe]
})
export class DashboardModule {}
