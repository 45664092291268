import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';
import { RoutingPaths } from '../constants';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public baseRoute = RoutingPaths.StatusDashboard;
    public serviceBus = RoutingPaths.ServiceBus;
    public eventPublisher = RoutingPaths.EventPublisher;
    public reporting = RoutingPaths.Reporting;
    public decisions = RoutingPaths.Decisions;
    public showServiceBus: boolean;
    public showEventPublisher: boolean;
    public showReporting: boolean;
    public showDecisionExport: boolean;
    public showAuditing: boolean;

    constructor(private permissionService: PermissionsService) { }

    ngOnInit() {
        this.permissionService.canGetQueues$.subscribe((canGetQueues) => (
            this.showServiceBus = canGetQueues
        ));
        this.permissionService.canPublishEvents$.subscribe((canPublishEvents) => (
            this.showEventPublisher = canPublishEvents
        ));
        this.permissionService.canGetReports$.subscribe((canGetReports) => (
            this.showReporting = canGetReports
        ));

        this.permissionService.canExportDecisionScenarios$.subscribe((canExportDecisionScenarios) => {
            this.showDecisionExport = canExportDecisionScenarios
        });

        this.permissionService.canViewAudit$.subscribe((canViewAudit) => {
            this.showAuditing = canViewAudit;
        });
    }
}