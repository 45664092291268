import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInsightsSegments, Environment } from '@environments/environment.interface';
import { AmqpApiService } from '@services/amqp/amqp.service';
import { MessageDetailModel } from '@services/amqp/models/message-detail.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';

@Component({
    selector: 'app-active-message-details',
    templateUrl: './active-message-details.component.html',
    styleUrls: ['./active-message-details.component.scss'],
})
export class ActiveMessageDetailsComponent implements OnInit {
    public dataSource: MatTableDataSource<MessageDetailModel> = new MatTableDataSource<MessageDetailModel>();
    public displayedColumns: string[] = ['messageId', 'correlationId', 'partitionKey', 'body', 'action'];
    @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
    public pageSize: number;
    public queueName: string;
    public queueType: string;
    public showDeleteButton: boolean;
    public isProcessing: string;
    public error: Error;
    public errorMessageId: string;
    public deleteMessageSuccessful: boolean;

    constructor(public amqpService: AmqpApiService, private route: ActivatedRoute, private router: Router, private http: HttpClient, private permissionService: PermissionsService) { }
    private appInsightsSegments: AppInsightsSegments;
    private appInsightsSearchUrl = 'https://portal.azure.com/#blade/AppInsightsExtension/BladeRedirect/BladeName/searchV1/ResourceId/';
    private appInsightsResourceUrlPart1 = '/subscriptions/7718ac17-b080-4891-9672-2b50d2a19be1/resourceGroups/appInsightsSegmentsResourceGroups/providers/microsoft.insights/components/appInsightsSegmentsComponents';
    private appInsightsResourceUrlPart2 =
        '{"tables":["availabilityResults","requests","exceptions","pageViews","traces","customEvents","dependencies"],"timeContextWhereClause":"| where timestamp > datetime("startDate") and timestamp < datetime("endDate")","filterWhereClause":"| where customDimensions["MessageId"] in ("messageGuid")| order by timestamp desc","originalParams":{"eventTypes":[{"type":"availabilityResult","tableName":"availabilityResults"},{"type":"request","tableName":"requests"},{"type":"exception","tableName":"exceptions"},{"type":"pageView","tableName":"pageViews"},{"type":"trace","tableName":"traces"},{"type":"customEvent","tableName":"customEvents"},{"type":"dependency","tableName":"dependencies"}],"timeContext":{"durationMs":86400000},"filter":[{"dimension":{"displayName":"MessageId","tables":["availabilityResults","requests","exceptions","pageViews","traces","customEvents","dependencies"],"name":"customDimensions/MessageId","draftKey":"customDimensions/MessageId"},"values":["messageGuid"]}],"searchPhrase":{"originalPhrase":"","_tokens":[]},"sort":"desc"}}';

    ngOnInit() {
        this.queueName = this.route.snapshot.paramMap.get('queue');
        this.queueType = 'active';
        this.amqpService.getDlqMessages(this.queueName);
        this.amqpService.messageDetailSubject.next(null);
        this.amqpService.getActiveMessages(this.queueName);
        this.amqpService.messageDetailSubject.subscribe((messageDetailResponse: MessageDetailModel[]) => {
            this.dataSource.data = messageDetailResponse;
            if (messageDetailResponse) {
                this.pageSize = messageDetailResponse.length;
                this.dataSource.paginator = this.paginator;
            } else {
                this.pageSize = 0;
            }
        });
        this.getEnvironments().subscribe((envs) => {
            this.appInsightsSegments = envs.appInsightsSegments;
            this.appInsightsResourceUrlPart1 = this.appInsightsResourceUrlPart1.replace('appInsightsSegmentsResourceGroups', this.appInsightsSegments.resourceGroups);
            this.appInsightsResourceUrlPart1 = this.appInsightsResourceUrlPart1.replace('appInsightsSegmentsComponents', this.appInsightsSegments.components);
            this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.split('("').join('(\\"');
            this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.split('")').join('\\")');
            this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.replace('["MessageId"]', '[\\"MessageId\\"]');
        });
        this.permissionService.canDeleteMessage$.subscribe((canDeleteMessages) => (this.showDeleteButton = true));
    }

    onBack(): void {
        this.router.navigate(['/servicebus']);
    }

    private getAppInsightsUrl(messageId: string): string {
        const endDate = moment();
        const startDate = moment().subtract(3, 'days');
        this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.replace('endDate', endDate.toISOString());
        this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.replace('startDate', startDate.toISOString());
        const part1 = encodeURIComponent(`${this.appInsightsResourceUrlPart1}`);
        const part2 = encodeURIComponent(`${this.appInsightsResourceUrlPart2.split('messageGuid').join(messageId)}`);

        return `${part1}/BladeInputs/${part2}`;
    }

    getEnvironments(): Observable<Environment> {
        const environments = this.http.get<Environment>('../environments/configs/config.json');
        return environments;
    }

    onMessageIdClick(messageId: string): void {
        const url = `${this.appInsightsSearchUrl}${this.getAppInsightsUrl(messageId)}`;
        window.open(url, '_blank');
    }

    deleteMessage(messageId: string): void {
        if (confirm('Are you sure you want to delete message ' + messageId + '? This action is permanent.')) {
            this.isProcessing = messageId;
            this.errorMessageId = undefined;
            this.amqpService.deleteMessage(this.queueName, this.queueType, messageId).subscribe(
                () => {
                    this.isProcessing = undefined;
                    this.deleteMessageSuccessful = true;
                    this.reload();
                },
                (error: Error) => {
                    this.error = error;
                    this.errorMessageId = this.isProcessing;
                    this.isProcessing = undefined;
                    this.deleteMessageSuccessful = false;
                }
            );
        }
    }

    reload() {
        const url = this.router.url;
        this.router.navigateByUrl('/servicebus', { skipLocationChange: true }).then(() => this.router.navigate([url]));
    }
}
