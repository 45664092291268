<div class="navbar navbar-top">
  <table class="table">
    <thead>
      <tr>
        <th colspan="10">
        <td>
          <a href="/">
            <span class="navbar-brand">
              <img src="/assets/Regions-Home-Improvement-Financing-RGB.svg" class="icon-banner" />
            </span>
          </a>
        </td>
        <td>
          <a [routerLink]="baseRoute" class="nav-link">Status Dashboard</a>
        </td>
        <td *ngIf="showServiceBus">
          <a [routerLink]="serviceBus" class="nav-link">Service Bus Messages</a>
        </td>
        <td *ngIf="showEventPublisher">
          <a [routerLink]="eventPublisher" class="nav-link">Event Publisher</a>
        </td>
        <td *ngIf="showReporting">
          <a [routerLink]="reporting" class="nav-link">Reporting</a>
        </td>
        <td *ngIf="showDecisionExport">
          <a [routerLink]="decisions" class="nav-link">Scenario Export</a>
        </td>
        <td *ngIf="showAuditing">
          <a [routerLink]="'auditing'" class="nav-link">Auditing</a>
        </td>
      </tr>
    </thead>
  </table>
</div>
