import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Environment } from "@environments/environment.interface";
import { ENVIRONMENT } from "@environments/environment.token";
import { BaseService } from "@services/base.service";
import { AuthService } from "@services/shared/auth/auth.abstract";
import { Observable } from "rxjs/internal/Observable";
import { CopyApplicationRequestModel } from "src/model/application-copy-request.model";
import { ApplicationCopyOutputModel } from "src/model/application-copy.model";

@Injectable({
    providedIn: 'root'
})
export class AuditService extends BaseService {
    constructor(
        http: HttpClient,
        @Inject(ENVIRONMENT) environment: Environment,
        authService: AuthService,
    ) {
        super(environment, http, authService);
    }

    public copyApplications(guids: string[]): Observable<ApplicationCopyOutputModel> {
        let body = <CopyApplicationRequestModel>{
            applicationGuids: guids
        };
        return this.endpointPost<ApplicationCopyOutputModel>(`${this.environment.auditBaseUrl}/copyapplication`, body);
    }
}

