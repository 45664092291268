import { Observable, BehaviorSubject } from 'rxjs';

import { BaseService } from '@services/base.service';

export abstract class PermissionsService extends BaseService {
    protected canGetQueues: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public canGetQueues$: Observable<boolean> = this.canGetQueues.asObservable();

    protected canShovelQueues: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public canShovelQueues$: Observable<boolean> = this.canShovelQueues.asObservable();

    protected canPublishEvents: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public canPublishEvents$: Observable<boolean> = this.canPublishEvents.asObservable();

    protected canGetReports: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public canGetReports$: Observable<boolean> = this.canGetReports.asObservable();

    protected canDeleteMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public canDeleteMessage$: Observable<boolean> = this.canDeleteMessage.asObservable();

    protected canExportDecisionScenarios: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public canExportDecisionScenarios$: Observable<boolean> = this.canExportDecisionScenarios.asObservable();

    protected canViewAudit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public canViewAudit$: Observable<boolean> = this.canViewAudit.asObservable();

    public abstract initialize(): Observable<void>;

}
