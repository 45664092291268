import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Environment, EventModel } from '@environments/environment.interface';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';
import { Observable } from 'rxjs';
import { EventPublisherService } from '../../service/event/event-publisher.service';

@Component({
  selector: 'app-event-publisher',
  templateUrl: './event-publisher.component.html',
  styleUrls: ['./event-publisher.component.scss']
})
export class EventPublisherComponent implements OnInit {
  public dataSource: MatTableDataSource<EventModel> = new MatTableDataSource<EventModel>();
  public displayedColumns: string[] = ['eventType','id', 'actions'];
  public showPublishButton: boolean;
  environment: Environment;
  public loanMessageGuid: string;
  public documentMessageGuid: string;
  public applicationMessageGuid: string;
  public contractorMessageId: string;
  public fundingRequestMessageGuid: string;
  public sponsorMessageId: string;
  public createSpectrumDisbursementMessageId: string
  public userStateChangedMessageId: string
  public synchronizeSponsorReportEventMessageId: string
  public synchronizeContractorReportEventMessageId: string

  constructor(private permissionService: PermissionsService, private http: HttpClient, private eventPublisherService: EventPublisherService, private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.http.get<Environment>('../environments/configs/config.json').subscribe((env) => {
      this.dataSource.data = env.events;
    });


    this.permissionService.canPublishEvents$.subscribe((canPublishEvents) =>
        (this.showPublishButton = canPublishEvents));
  }

  public publish(byId: boolean, url: string, name: string): void {
    let messageGuid: string;
    if (name === 'LoanStateChangedEvent') {
      messageGuid = this.loanMessageGuid;
    } else if (name === 'DocumentStateChangedEvent') {
      messageGuid = this.documentMessageGuid;
    } else if (name === 'ApplicationStateChangedEvent') {
      messageGuid = this.applicationMessageGuid;
    } else if (name === 'FundingRequestStateChangedEvent') {
      messageGuid = this.fundingRequestMessageGuid;
    } else if (name === 'ContractorStateChangedEvent') {
      messageGuid = this.contractorMessageId;
    } else if (name === 'SponsorStateChangedEvent') {
      messageGuid = this.sponsorMessageId;
    } else if (name === 'CreateSpectrumDisbursementStateChangedEvent') {
      messageGuid = this.createSpectrumDisbursementMessageId;
    } else if (name === 'UserStateChangedEvent') {
      messageGuid = this.userStateChangedMessageId;
    } else if (name === 'SynchronizeSponsorReportEvent') {
      messageGuid = this.synchronizeSponsorReportEventMessageId;
    } else if (name === 'SynchronizeContractorReportEvent') {
      messageGuid = this.synchronizeContractorReportEventMessageId;
    }

    if (name === 'ContractorStateChangedEvent' && messageGuid == null) {
      if(window.confirm("Do you want to publish this event for all contractors?") === false){
        return;
      }
    }

    if (name === 'UserStateChangedEvent' && messageGuid == null) {
      if(window.confirm("Do you want to publish this event for all users?") === false){
        return;
      }
    }

    if (name === 'SynchronizeReportEvent') {
      if(window.confirm("Do you want to publish this event for all sponsors and contractors?") === false){
        return;
      }
    }

    if (((name === 'SynchronizeSponsorReportEvent' || name === 'SynchronizeContractorReportEvent') && messageGuid === null) || name === 'SynchronizeReportEvent') {
      this.publishEvent(this.eventPublisherService.publishWithBody(url, {}), name);
    } else if (name === 'SynchronizeSponsorReportEvent' && messageGuid !== null) {
      this.publishEvent(this.eventPublisherService.publishWithBody(url, { sponsorId: messageGuid }), name);
    } else if (name === 'SynchronizeContractorReportEvent' && messageGuid !== null) {
      this.publishEvent(this.eventPublisherService.publishWithBody(url, { contractorId: messageGuid }), name);
    } else if (messageGuid == null && byId === true) {
      this.openSnackBar("Must enter an Id", "Ok")
      throw new Error('Id can not be null');
    } else if (name === 'UpdateExistingBorrowersEvent') {
        this.publishEvent(this.eventPublisherService.publishWithBody(url, { Date: new Date().toUTCString() }), name);
    } else {
      if (byId === true || name === 'UserStateChangedEvent') {
        this.eventPublisherService.publishIdInUrl(url, messageGuid, name).subscribe({
          next: () => {
            this.openSnackBar(`${name} published`, "Ok")
          },
          error: () => {
            this.openSnackBar("Unable To Publish Event", "Ok")
          }
        })
      } else {
        this.eventPublisherService.publishIdInQuery(url, messageGuid, name).subscribe({
          next: () => {
            this.openSnackBar(`${name} published`, "Ok")
          },
          error: () => {
            this.openSnackBar("Unable To Publish Event", "Ok")
          }
        })
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
    this.loanMessageGuid=null;
    this.documentMessageGuid=null;
    this.applicationMessageGuid=null;
    this.fundingRequestMessageGuid=null;
    this.contractorMessageId=null;
    this.sponsorMessageId=null;
    this.userStateChangedMessageId=null;
    this.synchronizeSponsorReportEventMessageId=null;
    this.synchronizeContractorReportEventMessageId=null;
  }

  private publishEvent(obs: Observable<any>, name: string) {
    obs.subscribe({
      next: () => {
        this.openSnackBar(`${name} published`, "Ok")
      },
      error: () => {
        this.openSnackBar("Unable To Publish Event", "Ok")
      }
    });
  }
}
