import { BehaviorSubject, Subject } from "rxjs";
import { BaseService } from "../base.service";
import { MessageDetailModel } from "./models/message-detail.model";
import { MessageQueueCountModel } from "./models/message-queue-count.model";
import { PagedList } from "@services/shared/responses/paged-list";

export abstract class AmqpService extends BaseService {
    public abstract messageQueueCountSubject: BehaviorSubject<MessageQueueCountModel[]>;
    public abstract messagePageSubject: BehaviorSubject<PagedList<MessageDetailModel>>;
    public abstract shovelMessagesSubject: Subject<any>;
    public abstract getQueueCounts(): void;
    public abstract getDlqMessages(queue: string): void;
}
