import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpMethods } from "@constants";
import { Environment } from "@environments/environment.interface";
import { ENVIRONMENT } from "@environments/environment.token";
import { BaseService } from "@services/base.service";
import { AuthService } from "@services/shared/auth/auth.abstract";
import { GoogleAnalyticsService } from "@services/shared/google-analytics/google-analytics.abstract";
import { Observable, Subject } from "rxjs";




@Injectable({
    providedIn: 'root'
})
export class EventPublisherService extends BaseService {
    public eventPublishSubject: Subject<any> = new Subject<any>();

    constructor(
        http: HttpClient,
        @Inject(ENVIRONMENT) environment: Environment,
        authService: AuthService,
    ) {
        super(environment, http, authService);
    }

    public publishIdInUrl(urlString: string, guid: string, name: string): Observable<any> {
        let url: string;
        if (name === 'UserStateChangedEvent') {
            url = guid ? `${urlString}/${guid}/message` : `${urlString}/message`;
        } else {
            url = `${urlString}/${guid}/message`;
        }
        return this.endpointPost(url);
    }

    public publishIdInQuery(urlString: string, id: string, name: string): Observable<any> {
        if (id != null){
            if (name === 'ContractorStateChangedEvent'){
                const url = `${urlString}?contractorId=${parseInt(id)}`;
                return this.endpointPost(url);
            } else {
                const url = `${urlString}?sponsorId=${parseInt(id)}`;
                return this.endpointPost(url);
            }
        } else {
            const url = `${urlString}`;
            return this.endpointPost(url);          
        }
    }

    public publishWithBody<TBody>(urlString: string, body: TBody): Observable<any> {
        return this.endpointPost(urlString, body);
    }
}
