export enum RoutingPaths {
    ActiveMessages = 'activemessages',
    DeadLetterMessages = 'deadlettermessages',
    EventPublisher = 'eventPublisher',
    Reporting = 'reporting',
    StatusDashboard = 'dashboard',
    ServiceBus = 'servicebus',
    Decisions = 'decisions',
    Auditing = 'auditing',
}

export enum GoogleAnalyticsEventNames {
    PageView = 'page_view',
    WebApiInvocation = 'web_api_invocation',
}

export enum HttpMethods {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
    Patch = 'PATCH',
}

export enum PermissionEnum {
    //TODO: add proper role in Policy Server and then update this
    CanShovelQueues = 'AmqpService.ShovelQueues',
    CanGetQueues = 'AmqpService.GetQueues',
    CanPublishEvents = 'AmqpService.PublishEvents',
    CanGetReports = 'CanGetReports',
    CanExportDecisionScenarios = 'DecisionEngine.ExportDecisionScenario',
    CanCopyApplication = 'Audit.CanCopyApplication',
}
