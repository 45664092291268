import { FactoryProvider } from '@angular/core';
import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from '../../../environments/environment.token';
import { LoggerService } from '@services/shared/logger/logger.abstract';

import { WINDOW } from '@services/shared/window/window.provider';

import { GoogleAnalyticsService } from './google-analytics.abstract';
import { GoogleAnalyticsMockService } from './google-analytics.mock-service';
import { GoogleAnalyticsApiService } from './google-analytics.service';

// tslint:disable-next-line: no-any
const factory = (logger: LoggerService, environment: Environment, window: any) => {
    return new GoogleAnalyticsMockService(logger, environment);
};

export const GoogleAnalyticsServiceProvider: FactoryProvider = {
    deps: [LoggerService, ENVIRONMENT, WINDOW],
    provide: GoogleAnalyticsService,
    useFactory: factory,
};
