import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InstanceStatusModel, FailedAttempt } from '../models';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-instance-status-error-modal',
    templateUrl: './instance-status-error-modal.component.html',
    styleUrls: ['./instance-status-error-modal.component.scss'],
})
export class InstanceStatusErrorModalComponent implements OnInit {
    @Input() currentStatus: InstanceStatusModel;

    constructor(public activeModal: MatDialog, private datePipe: DatePipe) {}

    ngOnInit() {
    }

    getFormattedDate(date: Date): string {
        return date ? this.datePipe.transform(date, 'mediumTime') : 'N/A';
    }

    timeDiff(date1, date2): string {
        if (date2 == null) {
            date2 = new Date();
        }
        if (date1 && date2) {
            let diffInMilliSeconds = Math.abs(date1 - date2) / 1000;
            const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
            diffInMilliSeconds -= hours * 3600;
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;
            const seconds = Math.floor(diffInMilliSeconds);
            let difference = '';
            difference += (hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
            difference += (minutes === 1) ? `${minutes} minute` : `${minutes} minutes, `;
            difference += (seconds === 1) ? `${seconds} second` : `${seconds} seconds`;
            return difference;
        }
        return 'N/A';
    }
}
