import { Component, Input, OnInit } from '@angular/core';

import { ReportResultModel } from '../../report.models';

@Component({
    selector: 'app-table-data-view',
    templateUrl: './table-data-view.component.html',
    styleUrls: ['./table-data-view.component.scss']
})
export class TableDataViewComponent implements OnInit {
    @Input() reportData: ReportResultModel;

    constructor() { }

    ngOnInit() {
    }
}
