import { Component, OnInit } from '@angular/core';
import { BehaviourService } from './behaviour.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    private details = false;
    constructor(private behaviourService: BehaviourService) {}

    ngOnInit() {
        this.behaviourService.showDetails.subscribe(showDetails => {
            this.details = showDetails;
        });
    }

    public showDetails(show) {
        this.details = show;
        this.behaviourService.updateDetails(this.details);
    }

}
