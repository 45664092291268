import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ENVIRONMENT } from "@environments/environment.token";
import { Environment } from 'src/environments/environment.interface';
import { BaseService } from '@services/base.service';
import { AuthService } from '@services/shared/auth/auth.abstract';

import { Observable } from 'rxjs';
import * as lodash from 'lodash';

import { ReportArgumentValueModel, ReportResultModel, ReportResults } from './report.models';

@Injectable()
export class ReportService extends BaseService {
    constructor(
        http: HttpClient,
        @Inject(ENVIRONMENT) environment: Environment,
        authService: AuthService
    ) {
        super(environment, http, authService);
    }

    getReportList(): Observable<ReportResults> {
        let response = this.endpointGet<ReportResults>(`${this.environment.sqlReportUrl}/reports`);
        return response;
    }

    buildReportArgumentParameters(argumentValues: Array<ReportArgumentValueModel>): HttpParams {
        return lodash.transform(
            argumentValues,
            function (result, value: ReportArgumentValueModel) {
                if (value.value != null) {
                    let argName: string = value.argName;
                    if (argName.startsWith('@')) {
                        argName = argName.substring(1);
                    }
                    result[argName] = value.value;
                }
            },
            {}
        );
    }

    executeReport(name: string, argumentValues: Array<ReportArgumentValueModel>): Observable<ReportResultModel> {
        let params: HttpParams = this.buildReportArgumentParameters(argumentValues);
        return this.endpointGet<ReportResultModel>(`${this.environment.sqlReportUrl}/reports/${name}`, params);
    }

    exportReport(name: string, argumentValues: Array<ReportArgumentValueModel>): Observable<Blob> {
        let params: HttpParams = this.buildReportArgumentParameters(argumentValues);
        return this.endpointGetBlob(`${this.environment.sqlReportUrl}/reports/${name}/export`, params);
    }
}
