import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { UserManager } from 'oidc-client';
import { PermissionsService } from '../permissions/permissions.abstract';
import { AuthService } from './auth.abstract';

const loadUser = (
    userManager: UserManager,
    authService: AuthService,
    permissionsService: PermissionsService
): (() => Promise<void>) => {
    return (): Promise<void> =>
        userManager
            .getUser()
            .then(function (user) {
                authService.initialize(user);
            })
            .then(function () {
                 return permissionsService.initialize().toPromise();
            });
};

export const authenticationInitializationProvider: FactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: loadUser,
    deps: [UserManager, AuthService, PermissionsService],
    multi: true,
};
