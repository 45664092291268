
import { XhrFactory } from '@angular/common';import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { userManagerFactory } from '@services/shared/auth/user-manager.provider';
import { User, UserManager } from 'oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const loadSettingsSubscription = loadSettings('./environments/configs/config.json').subscribe(
    async (environment: Environment) => {
        loadSettingsSubscription.unsubscribe();
        if (environment.prodMode === true) {
            enableProdMode();
        }

        const userManager = userManagerFactory(environment);

        // intercept silent redirect and halt actual bootstrap
        if (userManager.settings.silent_redirect_uri != null && window.location.href.indexOf(userManager.settings.silent_redirect_uri) > -1) {
            return userManager.signinSilentCallback();
        }

        // handle signin callback
        if (userManager.settings.redirect_uri != null && window.location.href.indexOf(userManager.settings.redirect_uri) > -1) {
            const redirectedUser = await userManager.signinRedirectCallback();
            window.history.replaceState({}, window.document.title, redirectedUser.state);
        } else {
            // validate user existence
            const user: User = await userManager.getUser().catch(() => null);
            if (user == null) {
                return userManager.signinRedirect({ state: window.location.href });
            }
        }

        // bootstrap
        const extraProviders = [
            { provide: UserManager, useValue: userManager },
            { provide: ENVIRONMENT, useValue: Object.freeze(environment) },
        ];

        platformBrowserDynamic(extraProviders)
            .bootstrapModule(AppModule)
            .catch((error) => {
                document.body.innerHTML = 'Failed to initialize application, please try again.';
            });
    },
    (error) => {
        loadSettingsSubscription.unsubscribe();
        document.body.innerHTML = 'Failed to load application, please try again.';
    }
);

function loadSettings(configPath: string): Observable<Object> {
    class XMLHttpRequestFactory implements XhrFactory {
        constructor() {}
        build(): XMLHttpRequest {
            return new XMLHttpRequest();
        }
    }

    const httpClient = new HttpClient(new HttpXhrBackend(new XMLHttpRequestFactory()));
    return httpClient.get<Environment>(configPath).pipe(
        // tslint:disable-next-line: no-any
        map((response: any) => Object.assign({}, response))
    );
}
