<div class="container-fluid">

  <div class="row m-3" *ngIf="!reports">
    <div class="col-md-12 m-5 loading-report text-center">
      <div *ngIf="!hasReportListError" class="loadingSpinner">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
      <div *ngIf="hasReportListError" class="m-5 error-text">
        <p><i>Request for the list of available reports has returned an error.</i></p>
      </div>
    </div>
  </div>

  <div class="m-3" *ngIf="reports">
    <div class="row">
      <h5>Select Report</h5>
    </div>
    <div class="row mb-3">
      <div *ngIf="!!reportGroups" class="col-md-3 mb-2">
        <div>
          Report Group
        </div>
        <select class="form-control" [(ngModel)]="selectedReportGroup" (change)="onGroupChange()">
          <option *ngFor="let item of reportGroups" [ngValue]="item" aria-placeholder="Select Group">
            {{item.name}}
          </option>
        </select>
      </div>
      <div *ngIf="!!reportsInGroup" class="col-md-3">
        <div>
          Report
        </div>
        <select class="form-control" [(ngModel)]="selectedReport">
          <option *ngFor="let item of reportsInGroup" [ngValue]="item" aria-placeholder="Select Report">
            {{item.name}}
          </option>
        </select>
        <div class="report-description">{{selectedReport.description}}</div>
      </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.reportArguments && selectedReport.reportArguments.length > 0">
      <div class="row">
        <h5>Report Arguments</h5>
      </div>
      <div class="row mb-3">
        <div class="col-md-3 mb-2" *ngFor="let argument of selectedReport.reportArguments">
          <app-report-argument [reportArgument]="argument"></app-report-argument>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="selectedReport != null">
      <button type="button" class="btn btn-primary mr-3" (click)="runReport()">Execute</button>
      <button type="button" class="btn btn-outline-primary mr-3" (click)="exportReport()">Export</button>
    </div>
  </div>
</div>

<div class="card m-3" *ngIf="reports != null">
  <div class="card-block">
    <div class="m-5 loading-report text-center" *ngIf="selectedReportData == null">
      <div class="m-3" *ngIf="!isRequestingReport && !hasReportError">
        <p>
          <i>Select the desired report.
            <br>Select arguments for the report.
            <br>Click Execute button and the report data results will display here.
          </i>
        </p>
      </div>
      <div class="m-5 error-text" *ngIf="!isRequestingReport && hasReportError">
        <i>Request for the report with given params return returned an error.
          <br>Please try again later.
        </i>
      </div>
      <div class="m-5" *ngIf="isRequestingReport" class="loadingSpinner">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>

    <div *ngIf="selectedReportData != null">
      <app-table-data-view [reportData]="selectedReportData"></app-table-data-view>
      <div class="m-5 loading-report" *ngIf="selectedReportData.resultSets.length == 0">
        <div class="m-4 text-center">
          <i>Report returned no results with given parameters.</i>
        </div>
      </div>
    </div>
  </div>
</div>
