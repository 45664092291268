<div >
    <button mat-raised-button
            (click)='onBack()'
            style='width:80px'>
      Back
    </button>
  </div>

  <mat-table [dataSource]="dataSource" class="mat-elevation-z2 message-table" >
    <!-- Action -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Actions</mat-header-cell>
      <mat-cell *matCellDef="let mainRowModel">
        <div *ngIf="showDeleteButton">
          <button mat-raised-button
          (click)="deleteMessage(mainRowModel.messageId)">Delete</button>
        </div>
        <div>
          <mat-progress-spinner mode="indeterminate" [diameter]="20" *ngIf="isProcessing === mainRowModel.messageId"></mat-progress-spinner>
        </div>
        <div>
          <mat-error *ngIf="errorMessageId === mainRowModel.messageId">An error occurred and the message deletion was not successful. Please try again.</mat-error>
        </div>
    </mat-cell>
    </ng-container>

    <!-- Message Id -->
    <ng-container matColumnDef="messageId">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Message Id</mat-header-cell>
      <mat-cell *matCellDef="let mainRowModel">
        <div >
         <a [routerLink]="[]" (click)="onMessageIdClick(mainRowModel.messageId)"> {{mainRowModel.messageId}} </a>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Correlation Id-->
    <ng-container matColumnDef="correlationId">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Correlation Id</mat-header-cell>
      <mat-cell *matCellDef="let mainRowModel">
        <div class="cell">
          {{mainRowModel.correlationId}}
        </div>
      </mat-cell>
    </ng-container>

      <!-- Event Type -->
      <ng-container matColumnDef="partitionKey">
        <mat-header-cell *matHeaderCellDef mat-sort-header >Event Type</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel">
          <div class="cell">
            {{mainRowModel.partitionKey}}
          </div>
        </mat-cell>
      </ng-container>

      <!-- Body -->
      <ng-container matColumnDef="body">
        <mat-header-cell *matHeaderCellDef mat-sort-header >Body</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel">
          <div class="bodyCell">
            <pre>{{mainRowModel.body}}</pre>
          </div>
        </mat-cell>
      </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    [length]= "pageSize"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


  <div class="loadingSpinner">
    <mat-progress-spinner mode="indeterminate" *ngIf="!dataSource.data"></mat-progress-spinner>
  </div>
