<div class="arg-name-and-type" *ngIf="reportArgument">
  <div class="arg-name">
    {{reportArgument.name}}
  </div>
  <div class="arg-type">
    {{reportArgument.argType}}
  </div>
</div>
<div [ngClass]="{'invalid-input': reportArgument.hasError}" *ngIf="reportArgument">
  <input *ngIf="inputType !== 'boolean' && reportArgument.argValues == null" type="{{inputType}}" class="form-control" [(ngModel)]="reportArgument.value" (change)="onChange()" />
  <select *ngIf="inputType !== 'boolean' && reportArgument.argValues != null" class="form-control" [(ngModel)]="reportArgument.value" (change)="onChange()">
    <option value=""></option>
    <option *ngFor="let argValue of $any(reportArgument).argValues | keyvalue" [ngValue]="argValue.key">
      {{argValue.value}}
    </option>
  </select>
  <select *ngIf="inputType === 'boolean'" class="form-control" [(ngModel)]="reportArgument.value" (change)="onChange()">
    <option value=""></option>
    <option value="true">True</option>
    <option value="false">False</option>
  </select>
</div>
