import { Component, OnInit } from "@angular/core";
import { AuditService } from "@services/audit/audit.service";
import { ApplicationCopyModel, ApplicationCopyOutputModel } from "src/model/application-copy.model";

@Component({
    selector: 'app-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
    guids: string;
    results: ApplicationCopyModel[];
    guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    isProcessing = false;
    ngOnInit() { }

    constructor(private auditService: AuditService) { }

    public copyApplications() {
        this.results = [];
        var guids = this.splitGuids();
        this.isProcessing = true;
        this.auditService.copyApplications(guids).subscribe((x:ApplicationCopyOutputModel) => {
            this.results = x.applications;
            this.isProcessing = false;
        });
    }

    private splitGuids(): string[] {
        var split = this.guids.split('\n');
        for (var guid of split) {
            if (!this.guidRegex.test(guid)) {
                alert("this guid isn't valid: " + guid)
            }
        }
        return split;
    }
}
