import { Component, OnInit, Input } from '@angular/core';

import { ReportArgumentValueModel } from './../report.models';

@Component({
    selector: 'app-report-argument',
    templateUrl: './report-argument.component.html',
    styleUrls: ['./report-argument.component.scss']
})
export class ReportArgumentComponent implements OnInit {
    @Input() reportArgument: ReportArgumentValueModel;

    public inputType: string;
    private hasPrecision: boolean;

    constructor() { }

    ngOnInit() {
        this.setInputType();
        this.onChange();
    }

    onChange() {
        if (this.reportArgument) {
            this.reportArgument.hasError = !this.validateInput();
        }
    }

    setInputType(): string {
        if (this.inputType) {
            return this.inputType;
        }
        if (this.reportArgument) {
            let type: string = this.reportArgument.argType.toLowerCase();
            if (type.indexOf('(') > -1) {
                this.hasPrecision = true;
                type = type.substring(0, type.indexOf('('));
            }
            switch (type) {
                case 'bit':
                    this.inputType = 'boolean';
                    break;
                case 'bigint':
                case 'smallint':
                case 'int':
                case 'tinyint':
                    this.inputType = 'number';
                    break;
                case 'numeric':
                case 'decimal':
                case 'smallmoney':
                case 'money':
                case 'float':
                case 'real':
                    this.inputType = 'number';
                    break;
                case 'date':
                case 'datetimeoffset':
                case 'datetime2':
                case 'smalldatetime':
                case 'datetime':
                    this.inputType = 'date';
                    break;
                case 'time':
                    this.inputType = 'time';
                    break;
                default:
                    this.inputType = 'string';
                    break;
            }
            return this.inputType;
        }
    }
    validateInput(): boolean {
        if (!this.reportArgument.value) {
            this.reportArgument.value = undefined;
            return true;
        }
        switch (this.inputType) {
            case 'number':
                if (this.hasPrecision) {
                    return !RegExp(/[^\d.]/).exec(this.reportArgument.value);
                }
                return !RegExp(/[^\d]/).exec(this.reportArgument.value);
            case 'date':
                return Number(this.reportArgument.value.substring(0, this.reportArgument.value.indexOf('-'))) < 3000;
            case 'string':
                if (this.hasPrecision) {
                    let numberOfChars: string = this.reportArgument.argType.substring(this.reportArgument.argType.indexOf('(') + 1, this.reportArgument.argType.length - 1);
                    if (numberOfChars !== 'max' && Number(numberOfChars) < this.reportArgument.value.length) {
                        return false;
                    }
                }
                return true;
            default:
                return true;
        }
    }
}
