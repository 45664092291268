<mat-table [dataSource]="dataSource" class="mat-elevation-z2 event-publisher-table">

  <!-- Event Name -->
  <ng-container matColumnDef="eventType">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Event Type</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div>
        {{mainRowModel.eventName}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Id input box-->
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div *ngIf="mainRowModel.eventName==='LoanStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='loanMessageGuid'>
      </div>
      <div *ngIf="mainRowModel.eventName==='DocumentStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='documentMessageGuid'>
      </div>
      <div *ngIf="mainRowModel.eventName==='ApplicationStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='applicationMessageGuid'>
      </div>
      <div *ngIf="mainRowModel.eventName==='FundingRequestStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='fundingRequestMessageGuid'>
      </div>
      <div *ngIf="mainRowModel.eventName==='ContractorStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='contractorMessageId'>
        <div><small class="help-text">To publish for ALL contractors leave blank</small></div>
      </div>
      <div *ngIf="mainRowModel.eventName==='SponsorStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='sponsorMessageId'>
        <div><small class="help-text">To publish for ALL sponsors leave blank</small></div>
      </div>
      <div *ngIf="mainRowModel.eventName==='CreateSpectrumDisbursementStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='createSpectrumDisbursementMessageId' placeholder="Funding Request Id">
      </div>
      <div *ngIf="mainRowModel.eventName==='UserStateChangedEvent'">
        <input type="text" class="inputBox" [(ngModel)]='userStateChangedMessageId'>
        <div><small class="help-text">To publish for ALL users leave blank</small></div>
      </div>
      <div *ngIf="mainRowModel.eventName==='SynchronizeSponsorReportEvent'">
        <input type="text" class="inputBox" [(ngModel)]='synchronizeSponsorReportEventMessageId' placeholder="Sponsor Id">
      </div>
      <div *ngIf="mainRowModel.eventName==='SynchronizeContractorReportEvent'">
        <input type="text" class="inputBox" [(ngModel)]='synchronizeContractorReportEventMessageId' placeholder="Contractor Id">
      </div>
      <div *ngIf="mainRowModel.eventName==='SynchronizeReportEvent'">
        <div><small class="help-text">This publishes for ALL sponsors and contractors</small></div>
      </div>
      <div *ngIf="mainRowModel.eventName==='UpdateExistingBorrowersEvent'">
        <div><small class="help-text">This publishes for ALL borrowers that are in spectrum but not the loan db</small></div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Button to publish -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div *ngIf="showPublishButton">
        <button (click)="publish(mainRowModel.byId, mainRowModel.serviceUrl, mainRowModel.eventName)" mat-raised-button>Publish</button>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
