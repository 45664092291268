<div class="modal-header">
  <h6 class="modal-title"> {{ currentStatus?.service}} - {{currentStatus?.environment }}</h6>
  <button type="button" class="close" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="note">*Show the last 5 failures</p>
  <div *ngFor="let failAttempt of currentStatus?.properties.failedAttempts.slice().reverse()">
    <div class="error-container">
      <span class="badge badge-danger">Incident</span>
      <div class="error-container-body">
        <div class="error-container-group">
            <span class="sub-title">Failed: </span> {{getFormattedDate(failAttempt.failedDt)}}
        </div>
        <div class="error-container-group">
          <span class="sub-title">Recovered: </span> {{getFormattedDate(failAttempt.healtyDt)}}
        </div>
        <div class="error-container-group">
          <span class="sub-title">Time down: </span> {{timeDiff(failAttempt.failedDt, failAttempt.healtyDt)}}
        </div>
        <div class="error-container-group">
          <span class="sub-title">Attempts: </span> {{failAttempt.attempts}}
        </div>
        <div class="error-container-group">
          <span class="sub-title">Error: </span> {{failAttempt.error}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button mat-raised-button color="primary" type="button" mat-dialog-close>Close</button>
</div>
