<div class="modal-header">
  <h4 class="modal-title">{{ currentStatus?.service}} - {{currentStatus?.environment }}</h4>
  <button type="button" class="close" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <strong>Current status</strong>
  <div class="status-wrapper">
      <pre>{{ currentStatus?.raw | json }}</pre>
  </div>
  <div>
      <strong>Last time failed: </strong>
      <span>{{ getFormattedDate(currentStatus?.lastTimeFailed) }}</span>
  </div>
  <div>
      <strong>Last time up: </strong>
      <span>{{ getFormattedDate(currentStatus?.lastTimeUp) }}</span>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</div>
