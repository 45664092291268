import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InstanceStatusModel } from '../models';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-instance-status-info-modal',
    templateUrl: './instance-status-info-modal.component.html',
    styleUrls: ['./instance-status-info-modal.component.scss'],
})
export class InstanceStatusInfoModalComponent implements OnInit {
    @Input() currentStatus: InstanceStatusModel;
    @Input() failedStatusResponses: Array<InstanceStatusModel> = [];

    constructor(public activeModal: MatDialog, private datePipe: DatePipe) {}

    getFormattedDate(date: Date): string {
        return date ? this.datePipe.transform(date, 'mediumTime') : 'N/A';
    }

    ngOnInit() {}
}
