import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatusComponent } from './status/status.component';
import { DashboardComponent } from './dashboard.component';
import { StatusReportModelResolverService } from './status-report-model-resolver.service';
import { RoutingPaths } from '../constants';
import { AuthGuardService } from '@services/shared/auth/auth-guard.service';

const routes: Routes = [
    {
        path: RoutingPaths.StatusDashboard,
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: StatusComponent,
                resolve: {
                    model: StatusReportModelResolverService,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
