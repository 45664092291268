import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { BaseService } from '@services/base.service';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { Decision } from './models/decision.model';

@Injectable({
  providedIn: 'root'
})
export class DecisionEngineService extends BaseService {
  constructor(http: HttpClient, @Inject(ENVIRONMENT) environment: Environment, authService: AuthService) {
    super(environment, http, authService);
  }

  public getDecisionScenario(decisionGuid: string) {
    let response = this.endpointGet<Decision>(`${this.environment.decisionEngineUrl}/v2/decisions/${decisionGuid}/scenario`);
    return response;
  }
}
