import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class BehaviourService {
  public showDetails: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public errorDetails: Subject<boolean> = new BehaviorSubject<boolean>(false);

constructor() {
    this.readLocalStorage();
  }

  private readLocalStorage() {
    this.showDetails.next(JSON.parse(localStorage.getItem('showDetails')));
    this.errorDetails.next(JSON.parse(localStorage.getItem('errorDetails')));
  }

  private setDetailsToLocalStorage(show) {
    localStorage.setItem('showDetails', JSON.stringify(show));
  }

  private setErrorDetailsToLocalStorage(show) {
    localStorage.setItem('errorDetails', JSON.stringify(show));
  }

  updateDetails(show: boolean) {
    this.showDetails.next(show);
    this.setDetailsToLocalStorage(show);
  }

  updateErrorDetails(show: boolean) {
    this.errorDetails.next(show);
    this.setErrorDetailsToLocalStorage(show);
  }

}
