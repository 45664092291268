<div class="border control-main-container">
  <div class="header border-bottom">
      <h5>Settings</h5>
  </div>
  <div class="control-container border-bottom">
    <div class="control-container-header">
      <div class="control-container-subtitle">
        <span>General</span>
      </div>
    </div>
    <div class="control-container-body">
      <div>
        <span class="switch switch">
          <input type="checkbox"
            [checked]="details"
            class="switch"
            id="switch-details"
            (change)="showInstanceDetails()">
          <label class="switch-label" for="switch-details">Show Instance Details</label>
        </span>
      </div>
      <div>
        <span class="switch switch">
          <input type="checkbox"
            [checked]="errorDetails"
            class="switch"
            id="switch-error-details"
            (change)="showErrorInstanceDetails()">
          <label class="switch-label" for="switch-error-details">Show Error Details</label>
        </span>
      </div>
    </div>
  </div>

  <div class="control-container border-bottom">
    <div class="control-container-header clickable" (click)="displayEnvironments()">
      <div class="control-container-subtitle ">
        <span>Environments (Column) </span>
        <svg *ngIf="showEnvironments" class="bi bi-chevron-compact-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
        </svg>
        <svg *ngIf="!showEnvironments" class="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
        </svg>
      </div>
    </div>
    <div class="control-container-body" *ngIf="showEnvironments">
      <div *ngFor="let environment of model.environments">
        <span class="switch switch">
          <input type="checkbox"
            [checked]="true"
            class="switch purple"
            id="{{'switch-'+environment.name}}"
            (change)="displayEnvironmentColumn(environment)">
          <label class="switch-label" for="{{'switch-'+environment.name}}">{{environment.name}}</label>
        </span>
      </div>
    </div>
  </div>

  <div class="control-container">
    <div class="control-container-header clickable" (click)="displayServices()">
      <div class="control-container-subtitle" >
        <span>Services (Row) </span>
        <svg *ngIf="showServices" class="bi bi-chevron-compact-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
        </svg>
        <svg *ngIf="!showServices" class="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
        </svg>
      </div>
    </div>
    <div class="control-container-body" *ngIf="showServices">
      <div *ngFor="let service of model.services">
        <span class="switch switch">
          <input type="checkbox"
            [checked]="true"
            class="switch"
            id="{{'switch-'+service.name}}"
            (change)="displayServiceRow(service)">
          <label class="switch-label" for="{{'switch-'+service.name}}">{{service.name}}</label>
        </span>
      </div>
    </div>

  </div>
</div>
