import { Injectable } from '@angular/core';
import { Observable, timer, forkJoin, of, merge, from } from 'rxjs';
import { StatusReportModel, DashboardConfigurationResponse, ServiceModel, InstanceStatusModel, HealthCheckResponse, InstanceModel, SummaryReportModel, EnvironmentModel, SettingsResponse, InstanceConfiguration, ServiceConfigurationResponse, UrlType, BuildSettingsModel, HealthCheckStatusEnum, InstanceModelProperties, FailedAttempt } from './models';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, switchMap, timeout, tap, catchError, mergeMap, share, flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
export class DashboardService {
    private configuration$: Observable<DashboardConfigurationResponse>;
    private configuration: DashboardConfigurationResponse;
    private instances: InstanceModel[];
    private readonly pollInterval: number = 15000;
    constructor(private http: HttpClient) {
        console.log('creating dashboard service');
        this.configuration$ = this.loadConfiguration().pipe(share()); // TODO
    }

    getStatusReportModel(): Observable<StatusReportModel> {
        return this.configuration$.pipe(
            map(response => {
                return {
                    environments: response.environments.map(environment => {
                        const environmentModel = {
                            name: environment,
                            display: true,
                        } as EnvironmentModel;
                        return environmentModel;
                    }),
                    services: response.services
                        .filter(service => !service.disabled)
                        .map(service => {
                            const serviceModel = {
                                name: service.name,
                                display: true,
                                instances: new Map<string, InstanceStatusModel>(),
                                description: service.description
                            } as ServiceModel;
                            Object.keys(service.instances).forEach(key => (serviceModel.instances[key] = null));
                            return serviceModel;
                        }),
                } as StatusReportModel;
            })
        );
    }

    poll(): Observable<InstanceStatusModel> {
        console.log('polling');
        return timer(0, this.pollInterval).pipe(
            switchMap(x => forkJoin(this.instances.filter(instance => instance.properties.display).map(instance => this.getStatus(instance)))),
            mergeMap(x => x),
            mergeMap((interests: InstanceStatusModel[]) => {
                return from(interests);
            })
        );
    }

    private loadConfiguration(): Observable<DashboardConfigurationResponse> {
        console.log('loading configuration...');
        return this.http.get<DashboardConfigurationResponse>('/environments/configs/config.dashboard.json').pipe(
            tap(response => {
                this.configuration = response;
                const list: InstanceModel[] = [];
                response.services.forEach(service => {
                    for (const [env, instances] of Object.entries(service.instances)) {
                        if (this.configuration.environments.includes(env) && !service.disabled) {
                            instances.forEach((instanceConfig: InstanceConfiguration) => {
                                let instance = { service: service.name, type: service.type, environment: env, healthUrl: instanceConfig.health, settingsUrl: instanceConfig.settings, properties: null, description: service.description};
                                instance = this.setInstanceDefaultProperties(instance);
                                list.push(instance);
                            });
                        }
                    }
                });
                this.instances = list;
            })
        );
    }

    private getStatus(instance: InstanceModel): Observable<InstanceStatusModel[]> {
        const requests: Observable<any>[] = [];
        if (instance.properties === null) {
            instance = this.setInstanceDefaultProperties(instance);
        }

        if (instance.settingsUrl) {
            requests.push(this.getSettings(instance.settingsUrl));
        }

        requests.push(this.getHealthCheck(instance.healthUrl, instance.type));
        return forkJoin(requests).pipe(
            mergeMap((responses: [SettingsResponse, HealthCheckResponse]) => {
                const healthCheckResponse: HealthCheckResponse = responses[responses.length - 1] as HealthCheckResponse;
                if (!healthCheckResponse.status) {
                    const service = this.findServiceByUrl(healthCheckResponse.url);
                    Object.assign(healthCheckResponse, service.responseOverride);
                }
                const combined = Object.assign({}, ...responses);
                const interests = this.instances
                    .filter(instance => instance.healthUrl === healthCheckResponse.url)
                    .map(instance => {
                        instance = this.checkHealth(instance, combined.healthy, healthCheckResponse);
                        return {
                            service: instance.service,
                            environment: instance.environment,
                            status: combined.status,
                            healthy: combined.healthy,
                            timestamp: combined.timestamp,
                            url: combined.url,
                            requestDate: new Date(),
                            version: combined.build ? combined.build.version : null,
                            tag: combined.build ? combined.build.tag : null,
                            suffix: combined.build ? combined.build.suffix : null,
                            raw: combined.raw ? combined.raw : combined,
                            properties: instance.properties
                        } as InstanceStatusModel;
                    });
                return of(interests);
            })
        );
    }

    private checkHealth(instance: InstanceModel, healty: boolean, healthCheckResponse: HealthCheckResponse): InstanceModel {
        if (instance.properties == null) {
            instance = this.setInstanceDefaultProperties(instance);
        }
        // Add or update a new health status if changes from fail to healthy or vice versa
        if (healty !== instance.properties.healthy) {
            if (!healty) {
                const failures = instance.properties.failedAttempts.length;
                if (failures >= 5) { // max 5 in the history to prevent memory problems
                    instance.properties.failedAttempts.shift();
                 }
                instance.properties.failedAttempts.push({
                    failedDt: new Date(),
                    healtyDt: null,
                    attempts: 0,
                    error: healthCheckResponse.error
                } as FailedAttempt);
                instance.properties.healthy = false;
            } else {
                const failures = instance.properties.failedAttempts.length;
                instance.properties.healthy = true;
                if (failures > 0) {
                    instance.properties.failedAttempts[failures - 1].healtyDt = new Date();
                }
            }
        }

        if (!healty) {
            // update current failed attemp
            const failures = instance.properties.failedAttempts.length;
            if (failures > 0) {
                instance.properties.failedAttempts[failures - 1].attempts++;
            }
            instance.properties.failedCounter++;
            instance.properties.failed = true;
        }
        return instance;
    }

    private setInstanceDefaultProperties(instance: InstanceModel): InstanceModel {
        instance.properties =
        {
            display: true,
            healthy: true,
            failed: false,
            firstTimeFailed: null,
            healtyTimeABeforeFailed: null,
            failedCounter: 0,
            failedAttempts: [] as FailedAttempt[]
        } as InstanceModelProperties;
        return instance;
    }

    private getHealthCheck(url: string, type: UrlType): Observable<HealthCheckResponse> {
        const service = this.findServiceByUrl(url);
        const headers = (service.requestHeaders || {}) as HttpHeaders;
        const responseType = type !== UrlType.Api ? ('text' as 'json') : 'json';
        return this.http
            .get<HealthCheckResponse>(url, {
                observe: 'response',
                headers,
                responseType,
            })
            .pipe(
                timeout(this.pollInterval + -500), // TODO: should have a separate timeout value
                map((response: HttpResponse<HealthCheckResponse>) => {
                    let newResponse: HealthCheckResponse = {} as HealthCheckResponse;
                    if (type !== UrlType.Api) {
                        newResponse.status = HealthCheckStatusEnum.ok;
                        newResponse.healthy = true;
                    } else {
                        newResponse = response.body || ({} as HealthCheckResponse);
                        if (newResponse.status) {
                            newResponse.status = newResponse.status.toLowerCase();
                        } else {
                            newResponse = this.formatJsonResponse(response.body) || ({} as HealthCheckResponse);
                        }
                    }
                    newResponse.url = url;
                    return newResponse;
                }),
                catchError((e: Error) => {
                    const status: string = HealthCheckStatusEnum.failure;
                    const failedResponse: HealthCheckResponse = {
                        url,
                        status,
                        healthy: false,
                        raw: {
                            url,
                            status,
                            healthy: false,
                        },
                        error: e.message
                    } as HealthCheckResponse;
                    return of(failedResponse);
                })
            );
    }

    private formatJsonResponse(body: HealthCheckResponse): HealthCheckResponse {
        const lowerCaseResponse = JSON.stringify(body).toLowerCase();
        body = JSON.parse(lowerCaseResponse);
        return body;
    }

    private getSettings(url: string): Observable<SettingsResponse> {
        const service = this.findServiceByUrl(url);
        const headers = (service.requestHeaders || {}) as HttpHeaders;
        return this.http
            .get<SettingsResponse>(url, {
                headers,
            })
            .pipe(
                catchError((e: Error) => {
                    const failedResponse: SettingsResponse = {
                        build: null,
                    };
                    return of(failedResponse);
                })
            );
    }

    private findServiceByUrl(url: string): ServiceConfigurationResponse {
        return this.configuration.services.find(s => JSON.stringify(s).includes(url));
    }
}
