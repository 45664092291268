import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Environment } from '../environments/environment.interface';
import { from, merge, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from './shared/auth/auth.abstract';

export enum HeaderName {
    Accept = 'Accept',
    Authorization = 'Authorization',
    ContentType = 'Content-Type',
}

export enum ContentTypeHeaderValue {
    Json = 'application/json',
}

export enum AcceptHeaderValue {
    Json = 'application/json',
}

export enum AuthorizationHeaderTokenType {
    Bearer = 'Bearer',
}

export abstract class BaseService {
    public apiBaseUrl = '';

    protected headers: HttpHeaders = new HttpHeaders();

    constructor(public environment: Environment, public http: HttpClient, public authService: AuthService) {
       if(this.environment)
        this.apiBaseUrl = this.environment.apiBaseUrl;
    }

    protected endpointGet<T>(url: string, httpParams?: HttpParams): Observable<T> {

        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) =>
                this.http.get<T>(url, {
                    headers: httpOptions.headers,
                    params: httpParams,
                })
            )
        );
    }

    protected endpointGetBlob<T>(url: string, httpParams?: HttpParams): Observable<Blob> {

        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) =>
                this.http.get(url, {
                    headers: httpOptions.headers,
                    params: httpParams,
                    responseType: 'blob'
                })
            )
        );
    }

    protected endpointDelete<T>(url: string): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.delete<T>(url, httpOptions))
        );
    }
    // tslint:disable-next-line: no-any
    protected endpointPost<T>(url: string, body?: any): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.post<T>(url, body, httpOptions))
        );
    }
    // tslint:disable-next-line: no-any
    protected endpointPut<T>(url: string, body?: any): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.put<T>(url, body, httpOptions))
        );
    }

    // tslint:disable-next-line: no-any
    protected endpointPatch<T>(url: string, body?: any): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.patch<T>(url, body, httpOptions))
        );
    }

    private generateHeadersUsingToken(token: string): Observable<{ headers: HttpHeaders }> {
        const httpOptions: { headers: HttpHeaders } = this.withAcceptHeader(AcceptHeaderValue.Json).withAuthorizationHeader(token).build();
        return of(httpOptions);
    }

    private getAccessToken(): Observable<string> {
        const getToken: Promise<string> = this.authService.getAccessToken().then((token: string) => {
            if (!token) {
                // redirects the user to login, so the returned value doesn't matter
                this.authService.loginUser();
                return '';
            }

            return token;
        });

        return from(getToken);
    }

    private withAcceptHeader(type: AcceptHeaderValue): this {
        this.headers = this.headers.set(HeaderName.Accept, type);
        return this;
    }

    private withAuthorizationHeader(authToken: string): this {
        if (!authToken) {
            throw new Error(`Can't build an Authentication header without an auth token!`);
        }

        this.headers = this.headers.set(HeaderName.Authorization, `${AuthorizationHeaderTokenType.Bearer} ${authToken}`);
        return this;
    }

    // not used as of now, but a useful function to have
    /* tslint:disable:no-unused-variable */
    private withContentTypeHeader(type: ContentTypeHeaderValue): this {
        this.headers = this.headers.set(HeaderName.ContentType, type);
        return this;
    }

    private build(): { headers: HttpHeaders } {
        return {
            headers: this.headers,
        };
    }
}
