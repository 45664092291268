import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';

import { AuthService } from '../auth/auth.abstract';

import { PermissionsService } from './permissions.abstract';
import { PermissionsMockService } from './permissions.mock-service';
import { PermissionsApiService } from './permissions.service';

const factory = (environment: Environment, http: HttpClient, authService: AuthService, googleAnalyticsService: GoogleAnalyticsService) => {
    return environment.mockServicesEnabled ? new PermissionsMockService(environment) : new PermissionsApiService(environment, http, authService, googleAnalyticsService);
};

export const PermissionsServiceProvider: FactoryProvider = {
    deps: [ENVIRONMENT, HttpClient, AuthService, GoogleAnalyticsService],
    provide: PermissionsService,
    useFactory: factory,
};
