import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingPaths } from '../constants';
import { HttpClient } from '@angular/common/http';
import { Environment } from '@environments/environment.interface';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-servicebus',
  templateUrl: './servicebus.component.html',
  styleUrls: ['./servicebus.component.scss']
})
export class ServiceBusComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
