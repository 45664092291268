import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DecisionEngineService } from '@services/decision-engine/decision-engine.service';
import { Decision } from '@services/decision-engine/models/decision.model';

@Component({
  selector: 'app-decisions',
  templateUrl: './decisions.component.html',
  styleUrls: ['./decisions.component.scss']
})
export class DecisionsComponent {

  form = new FormGroup({
    decisionGuid: new FormControl('', [
      Validators.required, 
      Validators.pattern('^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$')])
  });

  public submitEnabled: boolean = true;
  public apiError: boolean = false;
  public decision: Decision;
  public downloadJsonHref: SafeUrl;

  constructor(private decisionEngineService: DecisionEngineService, private sanitizer: DomSanitizer) { }

  onClick(): void {
    if (this.form.value?.decisionGuid) {
      this.submitEnabled = false;
      this.decisionEngineService.getDecisionScenario(this.form.value?.decisionGuid)
        .subscribe({
          next: result => { 
            this.decision = result;
            this.submitEnabled = true;
          },
          error: () => this.apiError = true
        });
    }
  }

  download() {
    this.generateDownloadJsonUri();
  }

  generateDownloadJsonUri() {
    const blob = JSON.stringify(this.decision, null, 2);
    var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(blob));
    this.downloadJsonHref = uri;
  }
}
