export enum EnvironmentName {
    Dev = 'dev',
    Test = 'test',
    Staging = 'staging',
    Prod = 'prod',
    Demo = 'demo',
    Uat = 'uat',
    Docker = 'docker',
    Mocked = 'mocked',
    E2EMocked = 'e2e-mocked',
}
