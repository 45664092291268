import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInsightsSegments, Environment } from '@environments/environment.interface';
import { AmqpApiService } from '@services/amqp/amqp.service';
import { MessageDetailModel } from '@services/amqp/models/message-detail.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';
import { PagedList } from '@services/shared/responses/paged-list';

@Component({
    selector: 'dead-letter-message-details',
    templateUrl: './dead-letter-message-details.component.html',
    styleUrls: ['./dead-letter-message-details.component.scss']
})

export class DeadLetterMessageDetailsComponent implements OnInit {
    public dataSource: MatTableDataSource<MessageDetailModel> = new MatTableDataSource<MessageDetailModel>();
    public displayedColumns: string[] = ['messageId', 'correlationId', 'partitionKey', 'body', 'action'];
    @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
    public queueName: string;
    public queueType: string;
    public showDeleteButton: boolean;
    public isProcessing: string;
    public error: Error;
    public errorMessageId: string;
    public deleteMessageSuccessful: boolean;



    constructor(public amqpService: AmqpApiService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private permissionService: PermissionsService) { }
    private appInsightsSegments: AppInsightsSegments;
    private appInsightsSearchUrl = 'https://portal.azure.com/#blade/AppInsightsExtension/BladeRedirect/BladeName/searchV1/ResourceId/';
    private appInsightsResourceUrlPart1 = '/subscriptions/7718ac17-b080-4891-9672-2b50d2a19be1/resourceGroups/appInsightsSegmentsResourceGroups/providers/microsoft.insights/components/appInsightsSegmentsComponents';
    private appInsightsResourceUrlPart2 = '{"tables":["availabilityResults","requests","exceptions","pageViews","traces","customEvents","dependencies"],"timeContextWhereClause":"| where timestamp > datetime(\"startDate\") and timestamp < datetime(\"endDate\")","filterWhereClause":"| where customDimensions[\"MessageId\"] in (\"messageGuid\")| order by timestamp desc","originalParams":{"eventTypes":[{"type":"availabilityResult","tableName":"availabilityResults"},{"type":"request","tableName":"requests"},{"type":"exception","tableName":"exceptions"},{"type":"pageView","tableName":"pageViews"},{"type":"trace","tableName":"traces"},{"type":"customEvent","tableName":"customEvents"},{"type":"dependency","tableName":"dependencies"}],"timeContext":{"durationMs":86400000},"filter":[{"dimension":{"displayName":"MessageId","tables":["availabilityResults","requests","exceptions","pageViews","traces","customEvents","dependencies"],"name":"customDimensions/MessageId","draftKey":"customDimensions/MessageId"},"values":["messageGuid"]}],"searchPhrase":{"originalPhrase":"","_tokens":[]},"sort":"desc"}}';

    // This array holds *previous page* sequence numbers for each page in the paginator.
    // The value at paginator.pageIndex should hold the sequence number of the last message on the previous page, or null for page index 0.
    private sequenceNumbersByPage: (number | null)[] = [null];

    ngOnInit() {
        this.amqpService.messagePageSubject.next(null);
        this.queueName = this.route.snapshot.paramMap.get('queue');
        this.queueType = 'deadletter';

        if (this.paginator)
            this.paginator.pageSize = this.previousPageSize;

        // Subscribe to the message count to set totalItems for the paginator
        this.amqpService.messageQueueCountSubject.subscribe(mqc => {
            this.paginator.length = mqc?.find(count => count.queueName === this.queueName)?.deadLetterMessageCount ?? 0;
            this.paginator.pageIndex = 0;
        });
        this.amqpService.messagePageSubject
            .subscribe((messageDetailResponse: PagedList<MessageDetailModel>) => {
                this.dataSource.data = messageDetailResponse?.items ?? [];
                this.paginator.length = messageDetailResponse?.totalItems ?? 0;

                if (messageDetailResponse) {
                    const sequenceNumberOfLastMessage = messageDetailResponse.items[messageDetailResponse.items.length - 1].systemProperties.sequenceNumber;
                    this.sequenceNumbersByPage[this.paginator.pageIndex + 1] = sequenceNumberOfLastMessage;
                }
            });

        // Send an initial request so the subjects above get triggered
        // This is null-safe to pass tests but it should default to previousPageSize when actually run
        this.amqpService.getDlqMessages(this.queueName, this.paginator?.pageSize ?? this.previousPageSize, null);

        this.getEnvironments().subscribe(
            (envs) => {
                this.appInsightsSegments = envs.appInsightsSegments
                this.appInsightsResourceUrlPart1 = this.appInsightsResourceUrlPart1.replace('appInsightsSegmentsResourceGroups', this.appInsightsSegments.resourceGroups);
                this.appInsightsResourceUrlPart1 = this.appInsightsResourceUrlPart1.replace('appInsightsSegmentsComponents', this.appInsightsSegments.components);
                this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.split('("').join('(\\"');
                this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.split('")').join('\\")');
                this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.replace('["MessageId"]', '[\\"MessageId\\"]');
            }
        );
        this.permissionService.canDeleteMessage$.subscribe((canDeleteMessages) => (this.showDeleteButton = true));
    }

    // The paginator's pageSize before a page event
    previousPageSize: number = 10;
    handlePageEvent(event: PageEvent) {
        // If the page size has changed then go back to page 0.
        // This is unfortunately necessary because paging into the service bus is done by message sequence number and not by index in the bus.
        if (event.pageSize != this.previousPageSize) {
            this.previousPageSize = event.pageSize;
            // Rese the paging array so only the first page's previous sequence (null) is known.
            this.sequenceNumbersByPage = [null];
            this.paginator.pageIndex = event.pageIndex = 0;
            // Recurse into this handler with the updatd page index.
            this.handlePageEvent(event);
        } else {
            const previousPageLastSequenceNumber = this.sequenceNumbersByPage[Math.max(event.pageIndex, 0)];
            this.dataSource.data = null;
            this.amqpService.getDlqMessages(this.queueName, event.pageSize, previousPageLastSequenceNumber);
        }
    }

    onBack(): void {
        this.router.navigate(['/servicebus']);
    }

    private getAppInsightsUrl(messageId: string): string {
        const endDate = moment();
        const startDate = moment().subtract(3, "days");
        this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.replace('endDate', endDate.toISOString());
        this.appInsightsResourceUrlPart2 = this.appInsightsResourceUrlPart2.replace('startDate', startDate.toISOString());
        const part1 = encodeURIComponent(`${this.appInsightsResourceUrlPart1}`);
        const part2 = encodeURIComponent(`${this.appInsightsResourceUrlPart2.split('messageGuid').join(messageId)}`);

        return `${part1}/BladeInputs/${part2}`
    }

    getEnvironments() : Observable<Environment>{
        const environments = this.http.get<Environment>('../environments/configs/config.json');
        return environments;
    }

    onMessageIdClick(messageId: string) : void{
        const url = `${this.appInsightsSearchUrl}${this.getAppInsightsUrl(messageId)}`;
        window.open(url, '_blank');
    }

    deleteMessage(messageId: string) : void{
        if(confirm("Are you sure you want to delete message "+messageId+"? This action is permanent.")){
            this.isProcessing = messageId;
            this.errorMessageId = undefined;
            this.amqpService.deleteMessage(this.queueName, this.queueType, messageId).subscribe(
                () => {
                    this.isProcessing = undefined;
                    this.deleteMessageSuccessful = true;
                    this.reload();
                },
                (error: Error) => {
                    this.error = error;
                    this.errorMessageId = this.isProcessing;
                    this.isProcessing = undefined;
                    this.deleteMessageSuccessful = false;
                }
            );
        }
    }

    reload() {
        const url = this.router.url;
        this.router.navigateByUrl('/servicebus', { skipLocationChange: true }).then(() => this.router.navigate([url]));
    }
}
