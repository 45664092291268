<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <h4>Copy Production Applications</h4>
                <textarea class="form-control" id="productionGuidInput" [(ngModel)]="guids" placeholder="Application Guids"></textarea>
                <button class="btn btn-primary" (click)="copyApplications()" [disabled]="isProcessing ? 'disabled': null">
                    <div *ngIf="isProcessing">
                        <mat-progress-spinner mode="indeterminate" [diameter]="20" *ngIf="isProcessing"></mat-progress-spinner>
                    </div>
                    <div *ngIf="!isProcessing">
                        Copy
                    </div>
                </button>

            </div>
        </div>
    </div>    
    <div class="row">
        <div class="col-md-12">
            <table class="table table-striped" *ngIf="results && results.length > 0">
                <tr>
                    <th>Source Application Id</th>
                    <th>Source Application Guid</th>
                    <th>Destination Application Id</th>
                    <th>Destination Application Guid</th>
                </tr>
                <tr *ngFor="let i of results">
                    <td>{{ i.sourceApplicationId }} </td>
                    <td>{{ i.sourceApplicationGuid }} </td>
                    <td>{{ i.destinationApplicationId }} </td>
                    <td>{{ i.destinationApplicationGuid }} </td>                    
                </tr>
            </table>
        </div>
    </div>
</div>
