import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Environment } from '@environments/environment.interface';
import { AmqpApiService } from '@services/amqp/amqp.service';
import { MessageQueueCountModel } from '@services/amqp/models/message-queue-count.model';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-servicebus-stats-table',
  templateUrl: './servicebus-stats-table.component.html',
  styleUrls: ['./servicebus-stats-table.component.scss']
})
export class ServicebusStatsTableComponent implements OnInit {
    public dataSource: MatTableDataSource<MessageQueueCountModel> = new MatTableDataSource<MessageQueueCountModel>();
    public displayedColumns: string[] = ['queueName','activeMessageCount', 'deadLetterMessageCount', 'scheduledMessageCount', 'actions'];
    @Input() environment: Observable<Environment>;
    public showShovelButton: boolean;
    public documentShovelButtonDisabled: boolean = false;
    public loanShovelButtonDisabled: boolean = false;
    public commsShovelButtonDisabled: boolean = false;
    public userShovelButtonDisabled: boolean = false;
    public fmsShovelButtonDisabled: boolean = false;
    public oaShovelButtonDisabled: boolean = false;
    public mdShovelButtonDisabled: boolean = false;
    public prequalificationShovelButtonDisabled: boolean = false;
    public spectrumDisbursementShovelButtonDisabled: boolean = false;
    public partnerportaladminShovelButtonDisabled: boolean=false;

  constructor(private amqpService: AmqpApiService,
    private permissionService: PermissionsService,
    private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.loadQueues();
  }

  private loadQueues(){
    this.amqpService.getQueueCounts();

    this.amqpService.messageQueueCountSubject
        .subscribe((queueCounts: MessageQueueCountModel[]) => {
            this.dataSource.data = queueCounts;
        });
    this.permissionService.canShovelQueues$.subscribe((canShovelQueues) =>
        (this.showShovelButton = canShovelQueues));
  }

  shovelMessages(queue: string): void {
      this.setButtonStatus(queue, true);
      this.amqpService.shovelMessages(queue);
      this.amqpService.shovelMessagesSubject.subscribe({
          next: () => {
              this.openSnackBar(`${queue} Messages Shoveled`, "Ok", queue)
            },
          error: () => {
              this.openSnackBar("Unable To Shovel Messages", "Ok", queue)
            }
      });
  }

  openSnackBar(message: string, action: string, queue: string) {
    this.setButtonStatus(queue, false);
    this._snackBar.open(message, action, {
      duration: 5000,
    });
    this.loadQueues();
  }

  getButtonStatus(queue: string): boolean {
    if (queue === "document.queue"){
        return  this.documentShovelButtonDisabled;
    } else if (queue === "loanservicing.queue" ){
        return this.loanShovelButtonDisabled;
    } else if (queue === "communications.queue" ){
        return this.commsShovelButtonDisabled ;
    } else if (queue === "user.queue" ){
        return  this.userShovelButtonDisabled;
    } else if (queue === "fundingmanagement.queue" ){
        return   this.fmsShovelButtonDisabled;
    } else if (queue === "onlineapplication.queue" ){
        return   this.oaShovelButtonDisabled;
    } else if (queue === "multidisbursement.queue" ){
        return   this.mdShovelButtonDisabled;
    } else if (queue === "prequalification.queue" ){
        return this.prequalificationShovelButtonDisabled;
    } else if (queue === "partnerportaladmin.queue" ){
        return this.partnerportaladminShovelButtonDisabled;
    }
  }

  setButtonStatus(queue: string, status: boolean): void {
    if (queue === "document.queue"){
         this.documentShovelButtonDisabled = status;
    } else if (queue === "loanservicing.queue" ){
        this.loanShovelButtonDisabled = status;
    } else if (queue === "communications.queue" ){
        this.commsShovelButtonDisabled = status;
    } else if (queue === "user.queue" ){
        this.userShovelButtonDisabled = status;
    } else if (queue === "fundingmanagement.queue" ){
        this.fmsShovelButtonDisabled = status;
    } else if (queue === "onlineapplication.queue" ){
        this.oaShovelButtonDisabled = status;
    } else if (queue === "multidisbursement.queue" ){
        this.mdShovelButtonDisabled = status;
    } else if (queue === "prequalification.queue" ) {
        this.prequalificationShovelButtonDisabled = status;
    }else if (queue === "partnerportaladmin.queue" ) {
        this.partnerportaladminShovelButtonDisabled = status;
    }
  }
}
