import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@services/shared/auth/auth-guard.service';
import { RoutingPaths } from './constants';

import { ActiveMessageDetailsComponent } from './servicebus/active-message-details/active-message-details.component';
import { DeadLetterMessageDetailsComponent } from './servicebus/dead-letter-message-details/dead-letter-message-details.component';
import { EventPublisherComponent } from './event-publisher/event-publisher.component';
import { ReportComponent } from './reporting/report/report.component';
import { ServiceBusComponent } from './servicebus/servicebus.component';
import { ServicebusStatsTableComponent } from './servicebus/servicebus-stats-table/servicebus-stats-table.component';
import { DecisionsComponent } from './decisions/decisions.component';
import { AuditComponent } from './audit/audit.component';

export const routes: Routes = [
    {
        path: RoutingPaths.ServiceBus,
        component: ServiceBusComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: ServicebusStatsTableComponent,
            },
            {
                path: `${RoutingPaths.DeadLetterMessages}/:queue`,
                component: DeadLetterMessageDetailsComponent,
            },
            {
                path: `${RoutingPaths.ActiveMessages}/:queue`,
                component: ActiveMessageDetailsComponent,
            },
        ],
    },
    {
        path: RoutingPaths.EventPublisher,
        component: EventPublisherComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: RoutingPaths.Reporting,
        children: [
            {
                path: '**',
                component: ReportComponent,
                canActivate: [AuthGuardService],
            }
        ]
    },
    {
        path: RoutingPaths.Decisions,
        component: DecisionsComponent
    },
    {
        path: RoutingPaths.Auditing,
        component: AuditComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: '**',
        redirectTo: RoutingPaths.StatusDashboard,
        pathMatch: 'full',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule { }
