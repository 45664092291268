export interface DashboardConfigurationResponse {
    environments: string[];
    services: ServiceConfigurationResponse[];
}

export interface ServiceConfigurationResponse {
    name: string;
    type: UrlType;
    instances: Map<string, InstanceConfiguration>;
    disabled: boolean;
    responseOverride: object;
    requestHeaders: object;
    description: string;
}

export interface InstanceConfiguration {
    key: string;
    health: string;
    settings: string;
}

export interface InstanceModel {
    service: string;
    environment: string;
    healthUrl: string;
    settingsUrl: string;
    type: UrlType;
    properties: InstanceModelProperties;
    description: string;
}

export interface SummaryReportModel {
    environments: EnvironmentModel[];
}

export interface StatusReportModel {
    environments: EnvironmentModel[];
    services: ServiceModel[];
}

export interface EnvironmentModel {
    name: string;
    instances: Map<string, InstanceStatusModel>;
    display: boolean;
}

export interface ServiceModel {
    name: string;
    instances: Map<string, InstanceStatusModel>;
    display: boolean;
    description: string;
}

export interface InstanceStatusModel {
    service: string;
    environment: string;
    status: string;
    healthy: boolean;
    timestamp: Date;
    url: string;
    requestDate: Date;
    lastTimeFailed: Date;
    lastTimeUp: Date;
    version: string;
    tag: string;
    suffix: string;
    raw: any;
    properties: InstanceModelProperties;
}

export interface InstanceModelProperties {
    display: boolean;
    healthy: boolean;
    failed: boolean;
    firstTimeFailed: Date;
    healtyTimeABeforeFailed: Date;
    failedCounter: number;
    failedAttempts: FailedAttempt[];
}

export interface FailedAttempt {
    failedDt: Date;
    healtyDt: Date;
    attempts: number;
    error: string;
}


export interface HealthCheckResponse {
    url: string;
    status: string;
    healthy: boolean;
    timestamp: Date;
    uptime: string;
    raw: any;
    build: BuildSettingsModel;
    error: string;
}

export interface SettingsResponse {
    build: BuildSettingsModel;
}

export interface BuildSettingsModel {
    tag: string;
    timestamp: string;
    version: string;
    suffix: string;
}

export enum HealthCheckStatusEnum {
    ok = 'ok',
    failure = 'failure',
    degraded = 'degraded',
}

export enum UrlType {
    Api = 'api',
    Page = 'page',
}
