import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { ReportGroupModel, ReportModel, ReportResultModel } from './../report.models';
import { ReportService } from './../report.service';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
    reports: Array<ReportModel>;
    reportGroups: Array<ReportGroupModel>;
    reportsInGroup: Array<ReportModel>;
    selectedReportGroup: ReportGroupModel;
    selectedReport: ReportModel;
    selectedReportData: ReportResultModel;
    isRequestingReport: boolean;
    hasReportError: boolean;
    hasReportListError: boolean;

    executionTimes: number;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private reportService: ReportService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.requestReportList();
    }

    requestReportList() {
        // Reset State
        this.reports = null;
        this.reportGroups = null;
        this.reportsInGroup = null;
        this.selectedReportData = null;
        this.selectedReportGroup;
        this.selectedReport = null;
        this.isRequestingReport = false;
        this.hasReportError = false;
        this.hasReportListError = false;

        // Get reports
        this.reportService.getReportList().subscribe({
            next: (result) => {
                this.reports = result.results;
                let reportGroupsMap = new Map<number, ReportGroupModel>();
                for (let report of this.reports) {
                    if (!reportGroupsMap.has(report.reportGroupId)) {
                        reportGroupsMap.set(report.reportGroupId, report.reportGroup);
                    }
                }
                this.reportGroups = Array.from(reportGroupsMap.values());

                this.selectInitialReport();
            },
            error: (err) => {
                console.log(err);
                this.hasReportListError = true;
                this.snackBar.open("Request for the list of available reports has returned an error.", "Ok", {
                    duration: 5000,
                });
            }
        });
    }

    selectInitialReport() {
        if (this.route.snapshot.url.length === 0) {
            this.onGroupChange(this.reportGroups[0]);
            return;
        }

        // Set selected state
        let reportName: string = this.route.snapshot.url[0].path;
        let report = this.reports.find(x => x.name == reportName);
        this.onGroupChange(report.reportGroup, report);

        // Pull paremeters and set them
        let params = this.route.snapshot.queryParamMap;
        for (let key of params.keys) {
            let arg = report.reportArguments.find(x => x.argName.replace('@', '') == key);
            if (arg) {
                arg.value = params.get(key);
            }
        }

        // Delay execution for the validation to run
        new Promise((resolve, reject) => {
            setTimeout(() => {
                this.runReport();
                resolve(1);
            }, 200);
        });
    }

    onGroupChange(thisGroup: ReportGroupModel = null, thisReport: ReportModel = null) {
        if (thisGroup && thisGroup.reportGroupId) {
            this.selectedReportGroup = thisGroup;
        }
        this.reportsInGroup = null;
        this.selectedReport = null;
        if (!this.selectedReportGroup) {
            return;
        }

        // Fill list of reports
        let reportList = new Array<ReportModel>();
        for (let report of this.reports) {
            if (report.reportGroup.reportGroupId === this.selectedReportGroup.reportGroupId) {
                reportList.push(report);
            }
        }
        this.reportsInGroup = reportList;

        // Set report
        if (thisReport) {
            this.selectedReport = thisReport;
        } else {
            this.selectedReport = reportList[0];
        }
    }

    isAbleToRunReport() : boolean {
        let invalidReport = this.selectedReport.reportArguments.find(x => x.hasError == true);
        return invalidReport == null;
    }

    runReport() {
        if (!this.isAbleToRunReport()) {
            this.snackBar.open("Report has invalid report arguments", "Ok", {
                duration: 5000,
            });
            return;
        }

        // Reset report state
        this.selectedReportData = null;
        this.isRequestingReport = true;
        this.hasReportError = false;

        // Update route
        let params: HttpParams = this.reportService.buildReportArgumentParameters(this.selectedReport.reportArguments);
        this.router.navigate(['/reporting/' + this.selectedReport.name], {queryParams: params});

        // Get report
        this.reportService.executeReport(this.selectedReport.name, this.selectedReport.reportArguments).subscribe({
            next: (data) => {
                this.selectedReportData = data;
                this.isRequestingReport = false;
            },
            error: (err) => {
                console.log(err);
                this.isRequestingReport = false;
                this.hasReportError = true;
                this.snackBar.open("Request for the report with given params return returned an error.", "Ok", {
                    duration: 5000,
                });
            }
        });
    }

    exportReport() {
        this.reportService.exportReport(this.selectedReport.name, this.selectedReport.reportArguments).subscribe((x) => {
            let newBlob = new Blob([x], { type: 'text/csv' });
            const data = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = data;
            link.download = this.selectedReport.name;
            link.click();
        });
    }
}
