import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { AuthService } from '@services/shared/auth/auth.abstract';

import { AmqpService } from './amqp.abstract';
import { AmqpApiService } from './amqp.service';

const amqpServiceFactory = (http: HttpClient, environment: Environment, authService: AuthService,) => {
    return new AmqpApiService(http, environment, authService, );
};

export const AmqpServiceProvider: FactoryProvider = {
    deps: [HttpClient, ENVIRONMENT, AuthService,],
    provide: AmqpService,
    useFactory: amqpServiceFactory,
};
