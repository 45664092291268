import { Inject, Injectable } from '@angular/core';
import { HttpMethods } from '@constants';
import { Environment, Identity, IdentityMetadata } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { LoggerService } from '@services/shared/logger/logger.abstract';
import { Log, User, UserManager } from 'oidc-client';

import { AuthService } from './auth.abstract';
import { WINDOW } from '../window/window.provider';

@Injectable()
export class AuthApiService extends AuthService {
    private apiLabel = 'Auth API';
    private user: User  = null;

    constructor(
        protected logger: LoggerService,
        @Inject(ENVIRONMENT) protected environment: Environment,
        private googleAnalyticsService: GoogleAnalyticsService,
        private userManager: UserManager,
        @Inject(WINDOW) private window: Window,
    ) {
        super(environment);

        Log.logger = console;

        this.userManager.events.addUserSignedOut(
            function() {
                this.user = null;
                this.logoutUser();
            }.bind(this)
          );

        this.userManager.events.addAccessTokenExpired(
            function() {
                this.user = null;
            }.bind(this)
        );

        this.userManager.events.addUserLoaded(
            function(u) {
                this.setUser(u);
            }.bind(this)
        );
    }

    public initialize(newUser: User) {
        this.setUser(newUser);
    }

    public setUser(newUser: User): void {
        this.user = newUser;
    }

    public getUser(): User {
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.Get, 'Get User', '');
        return this.user;
    }

    public isAuthenticated(): boolean {
        return this.user && !this.user.expired;
    }

    public startAuthentication(): Promise<void> {
        return this.userManager.signinRedirect({
            state: this.window.location.href,
        });
    }

    public async isUserLoggedIn(): Promise<boolean> {
        let user: User;

        try {
            user = this.getUser();
        } catch (e) {
            this.logger.error('Error in AuthService: isUserLoggedIn()');
            this.logger.error(e);
        }

        const isLoggedIn: boolean = user && !user.expired;

        this.logger.log(`AuthService: User is currently logged in: ${isLoggedIn}`);

        return isLoggedIn;
    }

    // TODO: adjust this when we need to be smarter about token refreshing/expiration.
    // TODO: should check if user's token is close to expiration or is expired, and if so, do a silent refresh
    public async shouldRefreshToken(): Promise<boolean> {
        this.logger.log('AuthService: shouldRefreshToken()');

        const user: User = this.getUser();

        const shouldRefresh: boolean = !user || user.expired;

        this.logger.log(`AuthService: Does token need to be refreshed?: ${shouldRefresh}`);

        return shouldRefresh; // in seconds
    }

    // TODO: hook this up to login/session stuff later instead of simply passing in hard-coded values.
    public async getAccessToken(): Promise<string> {
        this.logger.log('AuthService: getAccessToken()');

        const user: User = this.getUser();

        return (await this.shouldRefreshToken()) ? null : user.access_token;
    }

    public async getIdToken(): Promise<string> {
        this.logger.log('AuthService: getIdToken()');

        const user: User = this.getUser();

        return (await this.shouldRefreshToken()) ? null : user.id_token;
    }

    public async loginUser(): Promise<void> {
        this.logger.log('AuthService: Calling clearStaleState()');

        try {
            await this.userManager.clearStaleState();
        } catch (e) {
            this.logger.error('Error in AuthService: Calling UserManager.clearStaleState()');
            this.logger.error(e);
        }

        this.logger.log('AuthService: Calling signinRedirect()');

        try {
            // this will take the user away from the current page
            await this.userManager.signinRedirect();
        } catch (e) {
            this.logger.error('Error in AuthService: Calling UserManager.signinRedirect()');
            this.logger.error(e);
        }
    }

    public async logoutUser(): Promise<void> {
        this.logger.log('AuthService: Calling signoutRedirect()');

        try {
            // this will take the user away from the current page
            await this.userManager.signoutRedirect();
        } catch (e) {
            this.logger.error('Error in AuthService: Calling UserManager.signoutRedirect()');
            this.logger.error(e);
        }
    }
}
