import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable, interval, of, from, Subject } from 'rxjs';
import { map, tap, concatAll, switchMap, mergeAll, flatMap, mergeMap, delay, repeat, catchError } from 'rxjs/operators';

import { Status } from '../../model/health-check.model';
import { EnvironmentStatusModel, HealthCheckResponse, ServiceConfigurationResponse, StatusReportModel, ServiceModel, HealthCheckResultModel, ServiceEnvironmentModel } from 'src/model';

// TODO: move to core
// TODO: re-purpose this into ServiceHealthService...?
@Injectable()
export class HealthCheckService {
    private model: ServiceConfigurationResponse = null;
    private healthCheck$: Observable<HealthCheckResultModel> = null;
    public statusUpdate: Subject<HealthCheckResultModel> = new Subject<HealthCheckResultModel>();
    constructor(private http: HttpClient) {}

    initialize(): Observable<void> {
        return this.http.get<ServiceConfigurationResponse>('/environments/configs/config.dashboard.json').pipe(
            map(response => {
                this.model = response;
                const environments: ServiceEnvironmentModel[] = [];
                this.healthCheck$ = from(environments).pipe(
                    mergeMap(e => this.getEnvironmentStatus(e)),
                    delay(60000),
                    repeat()
                );
                return;
            })
        );
    }

    monitor(): void {
        this.healthCheck$.subscribe(x => {
            this.statusUpdate.next(x);
        });
    }

    getStatusReportModel(): Observable<StatusReportModel> {
        const model = {
            environments: this.model.environments,
            services: this.model.services.map(
                service =>
                    ({
                        environments: Object.keys(service.environments),
                        name: service.name,
                        type: service.type,
                    } as ServiceModel)
            ),
        } as StatusReportModel;

        return of(model);
    }

    private getEnvironmentStatus(service: ServiceEnvironmentModel): Observable<HealthCheckResultModel> {
        return this.http.get<HealthCheckResponse>(`${service.url}`).pipe(
            map((response: HealthCheckResponse) => {
                const model: HealthCheckResultModel = {
                    status: response.status,
                    healthy: response.healthy,
                    service: service.service,
                    environment: service.environment,
                    timestamp: response.timestamp,
                    uptime: response.uptime,
                };
                return model;
            }),
            catchError((e: HttpErrorResponse) => {
                return of({
                    status: e.statusText,
                    healthy: false,
                    service: service.service,
                    environment: service.environment,
                    timestamp: null,
                    uptime: null,
                });
            })
        );
    }
}
