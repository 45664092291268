import { Inject } from '@angular/core';

import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from '../../../environments/environment.token';

export abstract class GoogleAnalyticsService {
    protected envName: string;

    constructor(@Inject(ENVIRONMENT) protected environment: Environment) {
        this.envName = this.environment.name || '';
    }

    public abstract sendPageViewEvent(path: string): void;

    public abstract sendApiCallEvent(apiLabel: string, httpMethod: string, endpointLabel: string, endpointUrl: string): void;
}
