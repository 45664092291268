
<div class="row">
  <div class="col-sm-10">
    <div class="main-title">
      <h4>Services Status</h4>
    </div>
    <div>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th colspan="9">
              <span class="dot healthy space"></span>Healthy
              <span class="dot healthy degraded space"></span>Degraded
              <span class="dot unhealthy space"></span>Failure
            </th>
          </tr>
          <tr class="space-margin">
            <th><div class="service">Service Name</div></th>
            <th class="column" *ngFor="let environment of getEnvironments()"
                class="clickable title"
                [ngClass]="{'title-selected': (selectedEnv != null && environment.name === selectedEnv)}"
                (click)="selectEnv(environment.name)">
              <div *ngIf="environment.display">{{environment.name}}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let service of getServices()">
            <td class="service-name"><span>{{ service.name }}</span>
            <mat-icon matTooltip = "{{ service.description }}" [matTooltipPosition]="position.value" aria-hidden="false" aria-label="Info icon">info</mat-icon></td>
            <td *ngFor="let environment of getEnvironments()">
              <app-instance-status *ngIf="service.instances.hasOwnProperty(environment.name)"
                [status]="service.instances[environment.name]"
                [statusToCompare]="service.instances[selectedEnv]"
                [showDetails]="showDetails">
              </app-instance-status>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-sm-2">
    <app-controls [model]="model"></app-controls>
  </div>

</div>
