<mat-table [dataSource]="dataSource" class="mat-elevation-z2 servicebus-stats-table" >

  <!-- Queue Name -->
  <ng-container matColumnDef="queueName">
    <mat-header-cell *matHeaderCellDef mat-sort-header >Queue Name</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div >
        {{mainRowModel.queueName}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Active Message Count-->
  <ng-container matColumnDef="activeMessageCount">
    <mat-header-cell *matHeaderCellDef mat-sort-header >Active Message Count</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div *ngIf="mainRowModel.activeMessageCount===0">
        {{mainRowModel.activeMessageCount}}
      </div>
      <div *ngIf="mainRowModel.activeMessageCount > 0">
        <a [routerLink]="['/servicebus/activemessages', mainRowModel.queueName]" class="nav-link" >{{mainRowModel.activeMessageCount}}</a>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Dead Letter Message Count -->
  <ng-container matColumnDef="deadLetterMessageCount">
    <mat-header-cell *matHeaderCellDef mat-sort-header >Dead Letter Message Count</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div *ngIf="mainRowModel.deadLetterMessageCount===0">
        {{mainRowModel.deadLetterMessageCount}}
      </div>
      <div *ngIf="mainRowModel.deadLetterMessageCount > 0">
        <a [routerLink]="['/servicebus/deadlettermessages', mainRowModel.queueName]" class="nav-link" >{{mainRowModel.deadLetterMessageCount}}</a>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Scheduled Message Count -->
  <ng-container matColumnDef="scheduledMessageCount">
    <mat-header-cell *matHeaderCellDef mat-sort-header >Scheduled Message Count</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div >
        {{mainRowModel.scheduledMessageCount}}
      </div>
    </mat-cell>
  </ng-container>

    <!-- Shovel -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef >Actions</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel">
          <div *ngIf="showShovelButton && mainRowModel.deadLetterMessageCount > 0 && !getButtonStatus(mainRowModel.queueName)">
              <button mat-raised-button
              (click)="shovelMessages(mainRowModel.queueName)"
              [disabled]="getButtonStatus(mainRowModel.queueName)">Shovel</button>
            </div>
            <div>
              <mat-progress-spinner mode="indeterminate" [diameter]="20" *ngIf="getButtonStatus(mainRowModel.queueName)"></mat-progress-spinner>
            </div>
        </mat-cell>
    </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<div class="loadingSpinner">
  <mat-progress-spinner mode="indeterminate" *ngIf="!dataSource.data"></mat-progress-spinner>
</div>
