import { Inject, Injectable } from '@angular/core';

import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from '../../../environments/environment.token';

import { LoggerService } from './logger.abstract';

@Injectable()
export class LoggerApiService extends LoggerService {
    constructor(@Inject(ENVIRONMENT) protected environment: Environment) {
        super(environment);
    }

    public log(value: string): void {
        // tslint:disable-next-line: no-console
        console.log(this.logWithPrefix(value));
    }

    public error(value: string): void {
        // tslint:disable-next-line: no-console
        console.error(this.logWithPrefix(value));
    }
}
