import { Provider } from '@angular/core';
import { AuthGuardServiceProvider, AuthServiceProvider } from '@services/shared/auth/auth.provider';
import { GoogleAnalyticsServiceProvider } from '@services/shared/google-analytics/google-analytics.provider';
import { LoggerServiceProvider } from '@services/shared/logger/logger.provider';
import { PermissionsServiceProvider } from '@services/shared/permissions/permissions.provider';
import { WindowProvider } from '@services/shared/window/window.provider';
import { AmqpServiceProvider } from './amqp/amqpservice.provider';
import { authenticationInitializationProvider } from './shared/auth/authentication-initialization.provider';

export const ServiceProviders: Provider[] = [
    AuthServiceProvider,
    AuthGuardServiceProvider,
    GoogleAnalyticsServiceProvider,
    LoggerServiceProvider,
    PermissionsServiceProvider,
    authenticationInitializationProvider,
    WindowProvider,
    AmqpServiceProvider,
];
