import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpMethods, PermissionEnum } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.abstract';

import { PermissionsService } from './permissions.abstract';
import { take, map } from 'rxjs/operators';
import { PermissionResponseModel } from 'src/model/permissions-response.model';

@Injectable()
export class PermissionsApiService extends PermissionsService {
    private apiLabel = 'Permissions API';

    constructor(@Inject(ENVIRONMENT) environment: Environment, http: HttpClient, authService: AuthService, private googleAnalyticsService: GoogleAnalyticsService) {
        super(environment, http, authService);
    }

    public initialize(): Observable<void> {
        this.getReportAuthorizations().subscribe((data) => {
            this.canGetReports.next(data.permissions.some(x => x.endsWith(PermissionEnum.CanGetReports)));
        });
        this.getDecisionEngineAuthorizations().subscribe((data) => {
            this.canExportDecisionScenarios.next(data.permissions.some(x => x === PermissionEnum.CanExportDecisionScenarios));
        });
        this.getAuditAuthorization().subscribe((data) => {
            this.canViewAudit.next(data.permissions.some(x => x === PermissionEnum.CanCopyApplication));
        })
        return this.getAuthorizations().pipe(
            take(1),
            map((res) => {
                this.canGetQueues.next(res.permissions.some(x => x === PermissionEnum.CanGetQueues));
                this.canShovelQueues.next(res.permissions.some(x => x === PermissionEnum.CanShovelQueues));
                this.canPublishEvents.next(res.permissions.some(x => x === PermissionEnum.CanPublishEvents));
            })
        );
    }

    private getAuthorizations(): Observable<PermissionResponseModel> {
        const url = `${this.apiBaseUrl}/authorization`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.Post, 'Get Permissions', url);
        return this.endpointGet<PermissionResponseModel>(url);
    }

    private getReportAuthorizations(): Observable<PermissionResponseModel> {
        const url = `${this.environment.sqlReportUrl}/authorization`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.Post, 'Get SQL Report Auths', url);
        return this.endpointGet<PermissionResponseModel>(url);
    }

    private getDecisionEngineAuthorizations(): any {
        const url = `${this.environment.decisionEngineUrl}/v1/authorization`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.Post, 'Get Decision Engine Auths', url);
        return this.endpointGet<PermissionResponseModel>(url);
    }

    private getAuditAuthorization(): any {
        const url = `${this.environment.auditBaseUrl}/authorization`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.Post, 'Get Audit Auths', url);
        return this.endpointGet<PermissionResponseModel>(url);
    }
}
