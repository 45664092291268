import { Component, OnInit, OnDestroy } from '@angular/core';

import { EnviromentStatus, ActiveService } from '../model/health-check.model';

import { HealthCheckService } from '../service/health/health-check.service';
import { Subscription, interval } from 'rxjs';
import { ServiceConfigurationResponse } from 'src/model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(private healthCheckService: HealthCheckService) {}

    ngOnInit() {}

    ngOnDestroy() {}
}
