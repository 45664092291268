import { Injectable } from '@angular/core';
import { StatusReportModel } from './models';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardService } from './dashboard.service';

@Injectable({
    providedIn: 'root',
})
export class StatusReportModelResolverService  {
    constructor(private dashboardService: DashboardService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StatusReportModel> {
        return this.dashboardService.getStatusReportModel();
    }
}
