import { Environment } from '../../../environments/environment.interface';

export abstract class LoggerService {
    constructor(protected environment: Environment) {}

    protected get prefix(): string {
        return `[${new Date().toUTCString()}, ENV=${this.environment.name}]`;
    }

    protected logWithPrefix(value: string): string {
        return `${this.prefix}: ${value}`;
    }

    public abstract log(value: string): void;
    public abstract error(value: string): void;
}
