<div *ngFor="let reportSet of reportData?.resultSets" class="result-set">
  <table class="table table-striped table-responsive mb-0">
    <thead>
      <tr>
        <th *ngFor="let column of reportSet.columns">
          <div class="m-1">
            {{column.name}}
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="reportSet.rows == null || reportSet.rows.length == 0" class="no-rows">
        <td colspan="100">
          <div class="m-1 text-nowrap no-results">
            No results
          </div>
        </td>
      </tr>
      <tr *ngFor="let row of reportSet.rows">
        <td *ngFor="let item of row">
          <div *ngIf="item != null && item.toString().includes('</a>'); else textContent" class="m-1 text-nowrap">
            <div [innerHTML]="item"></div>
          </div>
          <ng-template #textContent>
            <div class="m-1 text-nowrap">
                {{item}}
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
