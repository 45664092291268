<div class="container-fluid">
    <div class="col-md-11 m-5">
        <form [formGroup]="form">
            <div class="row input-group">
                <div class="col-md-10">
                    <input class="form-control" name="decisionGuid" id="decisionGuid" placeholder="Decision Request Resource ID (GUID)" formControlName="decisionGuid" />
                    <div *ngIf="form.get('decisionGuid')?.errors?.pattern" class="alert alert-danger">
                        Decision Request Resource ID must be a GUID. Example: F5CD06F0-A95B-44D8-8C8F-5CFC596B3C9E
                    </div>
                    <div *ngIf="form.dirty && form.get('decisionGuid')?.errors?.required" class="alert alert-danger">
                        Decision Request Resource ID is a required value.
                    </div>
                    <div *ngIf="apiError" class="alert alert-danger">
                        Error retrieving decision scenario from Decision Engine API.
                    </div>                  
                </div>
                <div class="col-md-2">
                    <button class="form-control btn btn-primary" type="submit" [disabled]="!form.valid || !submitEnabled" (click)="onClick()">Search</button>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="decision" class="col-md-11 ml-5">
        <a class="btn btn-primary" title="Download JSON" [href]="downloadJsonHref" download="scenario.json" (click)="download()">Export Scenario</a>
    </div>
    <div *ngIf="decision" class="col-md-11 ml-5">
        <div class="row">
            <pre>
                <code highlight="{{ decision | json }}" [languages]="['json']" ></code>
            </pre>
        </div>
    </div>
</div>
