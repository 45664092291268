import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { StatusReportModel, EnvironmentModel, ServiceModel } from '../models';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BehaviourService } from '../behaviour.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnDestroy {
    model: StatusReportModel = null;
    private subscription: Subscription;
    public selectedEnv = 'prod';
    public showDetails: boolean;
    position: UntypedFormControl;

    constructor(private route: ActivatedRoute, private dashboardService: DashboardService, private behaviourService: BehaviourService) {
        this.model = route.snapshot.data.model;
        this.showDetails = false;
        this.position = new UntypedFormControl('after');
    }

    ngOnInit() {
        this.subscription = this.dashboardService.poll().subscribe(update => {
            if (this.model.services && this.model.services.length > 0) {
                this.model.services.find(s => s.name === update.service).instances[update.environment] = update;
            }
        });
        this.behaviourService.showDetails.subscribe(show => {
            this.showDetails = show;
        });
    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public selectEnv(env: string): void {
        this.selectedEnv = (env === this.selectedEnv ? '' : env);
    }

    public getEnvironments(): EnvironmentModel[] {
        return this.model?.environments?.filter(enviroment => enviroment.display);
    }

    public getServices(): ServiceModel[] {
        return this.model?.services?.filter(service => service.display);
    }
}
