import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Provider } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceProviders } from '@services/services.providers';
import { RouterModule, ROUTER_CONFIGURATION } from '@angular/router';
import { Environment } from '@environments/environment.interface';
import { EnvironmentName } from '@environments/environment-name.enum';
import { ENVIRONMENT } from '@environments/environment.token';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { AppComponent } from './app.component';

import { HealthCheckService } from '../service/health/health-check.service';
import { HeaderComponent } from './header/header.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ServiceBusComponent } from './servicebus/servicebus.component';
import { routes , AppRoutingModule } from './app-routing.module';
import { ServicebusStatsTableComponent } from './servicebus/servicebus-stats-table/servicebus-stats-table.component';
import { EventPublisherComponent } from './event-publisher/event-publisher.component';
import { DeadLetterMessageDetailsComponent } from './servicebus/dead-letter-message-details/dead-letter-message-details.component';
import { ReportComponent } from './reporting/report/report.component';
import { ReportArgumentComponent } from './reporting/report-argument/report-argument.component';
import { ReportService } from './reporting/report.service';
import { TableDataViewComponent } from './reporting/data-views/table-data-view/table-data-view.component';
import { ActiveMessageDetailsComponent } from './servicebus/active-message-details/active-message-details.component';
import { AuditComponent } from './audit/audit.component';
import { DecisionsComponent } from './decisions/decisions.component';
import { HighlightModule, HIGHLIGHT_OPTIONS, HighlightOptions } from 'ngx-highlightjs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

const initializeHealthCheck = (service: HealthCheckService): (() => Promise<void>) => () => service.initialize().toPromise();

const RouterProvider: Provider = {
    provide: ROUTER_CONFIGURATION, // this corresponds to the type of the second optional parameter of RouterModule.forRoot()
    useFactory: (environment: Environment) => {
        return { enableTracing: environment.name === EnvironmentName.Dev }; // <-- debugging purposes only
    },
    deps: [ENVIRONMENT],
};

@NgModule({
    declarations: [
        AppComponent,
        ActiveMessageDetailsComponent,
        DeadLetterMessageDetailsComponent,
        EventPublisherComponent,
        HeaderComponent,
        ServiceBusComponent,
        ServicebusStatsTableComponent,
        ReportComponent,
        ReportArgumentComponent,
        TableDataViewComponent,
        DecisionsComponent,
        AuditComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        DashboardModule,
        RouterModule.forRoot(routes, {}),
        BrowserAnimationsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatButtonModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatIconModule,
        HighlightModule,
        MatDialogModule,
        MatTooltipModule
    ],
    providers: [
        HealthCheckService,
        ServiceProviders,
        RouterProvider,
        ReportService,
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: <HighlightOptions>{
                lineNumbers: false,
                useBR: true,
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    json: () => import('highlight.js/lib/languages/json'),
                },
            },
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
