import { Component, OnInit, Input } from '@angular/core';
import { BehaviourService } from '../behaviour.service';
import { StatusReportModel, EnvironmentModel, ServiceModel } from '../models';

@Component({
    selector: 'app-controls',
    templateUrl: './controls.component.html',
    styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit {
    @Input() model: StatusReportModel = null;

    details: boolean;
    errorDetails: boolean;
    showEnvironments: boolean;
    showServices: boolean;
    constructor(private behaviourService: BehaviourService) {}

    ngOnInit() {
        this.behaviourService.showDetails.subscribe(showDetails => {
            this.details = showDetails;
        });
        this.behaviourService.errorDetails.subscribe(showErrorDetails => {
            this.errorDetails = showErrorDetails;
        });

        this.showEnvironments = false;
        this.showServices = false;
    }

    public showInstanceDetails() {
        this.details = !this.details;
        this.behaviourService.updateDetails(this.details);
    }

    public showErrorInstanceDetails() {
        this.errorDetails = !this.errorDetails;
        this.behaviourService.updateErrorDetails(this.errorDetails);
    }

    public displayEnvironments() {
        this.showEnvironments = !this.showEnvironments;
    }

    public displayServices() {
        this.showServices = !this.showServices;
    }

    public displayEnvironmentColumn(environment: EnvironmentModel) {
        environment.display = !environment.display;
        this.model.services.forEach(service => {
            if (service.instances[environment.name]) {
                service.instances[environment.name].properties.display = (environment.display && service.display);
            }
        });
    }

     public displayServiceRow(serviceModel: ServiceModel) {
        serviceModel.display = !serviceModel.display;
        this.model.environments.forEach(environment => {
            if (serviceModel.instances[environment.name]) {
                serviceModel.instances[environment.name].properties.display = (environment.display && serviceModel.display);
            }
        });
    }

}
